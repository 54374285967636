"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find-index");

var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/objectSpread2"));

require("core-js/modules/es6.number.constructor");

var _recommend = require("@/api/recommend");

var _goodsLink = _interopRequireDefault(require("../views/recommend/compoments/goodsLink"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    goodsLink: _goodsLink.default
  },
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    appendToBody: {
      type: Boolean,
      default: false
    },
    dialogWidth: {
      type: String,
      default: "1020px"
    },
    maxNum: {
      type: Number
    },
    showTypeSelect: {
      type: Boolean,
      default: false
    },
    pageSize: {
      type: Number,
      default: 8
    },
    showCheckedTool: {
      type: Boolean,
      default: false
    },
    showCate: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      searchForm: {
        goods_name: "",
        cate_id: null,
        page: 1
      },
      selectItems: [],
      goodsList: [],
      total: 0,
      categoryList: []
    };
  },
  methods: {
    // 升序
    upSort: function upSort(index) {
      if (index === 0) {
        return;
      }

      var item = this.selectItems[index];
      this.$set(this.selectItems, index, this.selectItems[index - 1]);
      this.$set(this.selectItems, index - 1, item);
    },
    // 降序
    downSort: function downSort(index) {
      if (index === this.selectItems.length - 1) {
        return;
      }

      var item = this.selectItems[index];
      this.$set(this.selectItems, index, this.selectItems[index + 1]);
      this.$set(this.selectItems, index + 1, item);
    },
    // 删除商品
    deleteGoods: function deleteGoods(index) {
      this.selectItems.splice(index, 1);
    },
    // 获取商品列表
    getList: function getList() {
      var _this = this;

      console.log(this.searchForm, "search");
      (0, _recommend.getGoodsList)((0, _objectSpread2.default)({
        pageSize: this.pageSize
      }, this.searchForm)).then(function (res) {
        _this.goodsList = res.data;
        _this.total = res.total;
      });
    },
    submit: function submit() {
      this.$emit("setSelect", this.selectItems);
      this.dialogVisible = false;
    },
    // 点击选中
    handleSelect: function handleSelect(select) {
      var index = this.selectItems.findIndex(function (item) {
        return item.goods_id == select.goods_id;
      });

      if (index > -1) {
        // 已经选中取消选中
        this.selectItems.splice(index, 1);
      } else {
        // 选中
        if (this.multiple) {
          // 多选
          if (this.selectItems.length < this.maxNum) {
            this.selectItems.push(select);
          } else {
            this.$message({
              message: "数量已达到可选上限",
              type: "warning"
            });
          }
        } else {
          // 单选
          this.selectItems = [select];
        }
      }
    },
    // 判断是否选中
    isSelect: function isSelect(id) {
      return this.selectItems.findIndex(function (item) {
        return item.goods_id == id;
      }) > -1;
    },
    // 页码切换
    handleCurrentChange: function handleCurrentChange(val) {
      this.searchForm.page = val;
      this.getList();
    },
    // 搜索商品
    search: function search() {
      if (this.searchForm.goods_name || this.searchForm.cate_id) {
        this.searchForm.page = 1;
        this.getList();
      }
    },
    // 重置
    clearSearch: function clearSearch() {
      this.searchForm.goods_name = '';
      this.searchForm.cate_id = '';
      this.searchForm.page = 1;
      this.getList();
    },
    // 清空数据
    reset: function reset() {
      this.searchForm = {
        goods_name: "",
        cate_id: null,
        page: 1
      };
      this.selectItems = [];
    },
    // 获取二级分类列表
    getSelectList: function getSelectList() {
      var _this2 = this;

      (0, _recommend.getCategoryList)().then(function (res) {
        _this2.categoryList = res;
      });
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.getList();

        if (this.showTypeSelect) {
          this.getSelectList();
        }
      } else {
        // 重置
        this.reset();
      }
    }
  }
};
exports.default = _default;
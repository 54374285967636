"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/objectSpread2"));

var _path = _interopRequireDefault(require("path"));

var _validate = require("@/utils/validate");

var _Item = _interopRequireDefault(require("./Item"));

var _Link = _interopRequireDefault(require("./Link"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SidebarItem',
  components: {
    Item: _Item.default,
    AppLink: _Link.default
  },
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    this.onlyOneChild = null;
    return {};
  },
  methods: {
    hasOneShowingChild: function hasOneShowingChild() {
      var _this = this;

      var children = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var parent = arguments.length > 1 ? arguments[1] : undefined;
      var showingChildren = children.filter(function (item) {
        if (item.hidden) {
          return false;
        } else {
          // Temp set(will be used if only has one showing child)
          _this.onlyOneChild = item;
          return true;
        }
      }); // Show parent if there are no child router to display

      if (showingChildren.length === 0) {
        this.onlyOneChild = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, parent), {}, {
          path: '',
          noShowingChildren: true
        });
        return true;
      }

      return false;
    },
    resolvePath: function resolvePath(routePath) {
      if ((0, _validate.isExternal)(routePath)) {
        return routePath;
      }

      if ((0, _validate.isExternal)(this.basePath)) {
        return this.basePath;
      }

      return _path.default.resolve(this.basePath, routePath);
    }
  }
};
exports.default = _default;
"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.saveParams = saveParams;
exports.getParamsByType = getParamsByType;
exports.releaseOnline = releaseOnline;
exports.previewIndex = previewIndex;
exports.categoryList = categoryList;
exports.addCategory = addCategory;
exports.editCategory = editCategory;
exports.setCategoryDel = setCategoryDel;
exports.setCategoryStatus = setCategoryStatus;
exports.setCategorySort = setCategorySort;
exports.listCategoryList = listCategoryList;
exports.setListSort = setListSort;
exports.goodsList = goodsList;
exports.setListStatus = setListStatus;
exports.addList = addList;
exports.chooseGoods = chooseGoods;
exports.getActivityPage = getActivityPage;
exports.editActivityPage = editActivityPage;
exports.release = release;

var _fetch = _interopRequireDefault(require("@/utils/fetch"));

/**
 *  小程序首页配置
 */
// 首页设置保存数据
function saveParams(params) {
  return _fetch.default.post("/Admin/AlipayIndex/setIndex", params, true);
} // 首页查询单个设置


function getParamsByType(type) {
  return _fetch.default.post("/Admin/AlipayIndex/findInfo", {
    type: type
  });
} // 首页设置发布


function releaseOnline() {
  return _fetch.default.post("/Admin/AlipayIndex/releaseOnline", {}, true);
} // 首页设置预览


function previewIndex() {
  return _fetch.default.post("/Admin/AlipayIndex/preview");
}
/**
 * 商品推广管理
 */
// 获取首页推荐分类列表


function categoryList(params) {
  return _fetch.default.post("/Admin/AlipayIndex/categoryList", params);
} // 首页推荐添加分类


function addCategory(params) {
  return _fetch.default.post("/Admin/AlipayIndex/addCategory", params, true);
} // 首页推荐编辑分类


function editCategory(params) {
  return _fetch.default.post("/Admin/AlipayIndex/editCategory", params, true);
} // 首页推荐删除分类


function setCategoryDel(id) {
  return _fetch.default.post("/Admin/AlipayIndex/setCategoryDel", {
    id: id
  }, true);
} // 首页推荐设置分类状态


function setCategoryStatus(params) {
  return _fetch.default.post("/Admin/AlipayIndex/setCategoryStatus", params, true);
} // 首页推荐设置分类排序


function setCategorySort(params) {
  return _fetch.default.post("/Admin/AlipayIndex/setCategorySort", params, true);
} // 首页推荐商品列表获取分类


function listCategoryList() {
  return _fetch.default.post("/Admin/AlipayIndex/listCategoryList");
} // 首页推荐商品列表设置商品推荐排序


function setListSort(params) {
  return _fetch.default.post("/Admin/AlipayIndex/setListSort", params, true);
} // 首页推荐商品列表


function goodsList(params) {
  return _fetch.default.post("/Admin/AlipayIndex/goodsList", params);
} // 首页推荐商品列表设置状态


function setListStatus(params) {
  return _fetch.default.post("/Admin/AlipayIndex/setListStatus", params, true);
} // 首页推荐商品列表添加商品


function addList(params) {
  return _fetch.default.post("/Admin/AlipayIndex/addList", params, true);
} // 首页推荐商品列表选择商品


function chooseGoods(params) {
  return _fetch.default.post("/Admin/AlipayIndex/chooseGoods", params);
}
/**
 * 九大分类
 */
// 获取活动页


function getActivityPage() {
  return _fetch.default.get("/Admin/ActivityManage/getActivityPage");
} // 编辑活动页


function editActivityPage(params) {
  return _fetch.default.post("/Admin/ActivityManage/editActivityPage", params, true);
} // 发布
// 编辑活动页


function release(id) {
  return _fetch.default.post("/Admin/ActivityManage/release", {
    id: id
  }, true);
}
"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _web = require("@/api/web");

var _customPage = _interopRequireDefault(require("@/components/customPage"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    customPage: _customPage.default
  },
  data: function data() {
    return {
      tableData: [],
      total: 0
    };
  },
  methods: {
    // 更改状态
    changeStatus: function changeStatus(id, status) {
      var _this = this;

      (0, _web.addOrEditH5HomeShow)({
        id: id,
        status: status == 1 ? 0 : 1
      }).then(function (res) {
        _this.getList();
      });
    },
    // 列表项删除
    removeItem: function removeItem(id) {
      var _this2 = this;

      this.$confirm('您确定要删除吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _web.delH5HomeShow)(id).then(function (res) {
          _this2.getList();
        });
      }).catch(function () {});
    },
    // 更改排序
    changeSort: function changeSort(id, val) {
      var _this3 = this;

      (0, _web.addOrEditH5HomeShow)({
        id: id,
        module_sort: val
      }).then(function (res) {
        _this3.getList();
      });
    },
    // 获取列表
    getList: function getList() {
      var _this4 = this;

      var _this$$refs$customPag = this.$refs.customPage,
          page = _this$$refs$customPag.page,
          pageSize = _this$$refs$customPag.pageSize;
      (0, _web.h5HomeShowList)({
        page: page,
        pageSize: pageSize
      }).then(function (res) {
        _this4.tableData = res.data || [];
        _this4.total = res.total;
      });
    }
  },
  mounted: function mounted() {
    this.getList();
  }
};
exports.default = _default;
"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/objectSpread2"));

var _toConsumableArray2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/toConsumableArray"));

require("core-js/modules/es6.array.find");

var _recommend = require("@/api/recommend");

var _form = _interopRequireDefault(require("../../recommend/compoments/form"));

var _goodsDialog = _interopRequireDefault(require("@/components/goodsDialog.vue"));

var _goodsLink = _interopRequireDefault(require("../../recommend/compoments/goodsLink"));

var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    goodsForm: _form.default,
    goodsDialog: _goodsDialog.default,
    goodsLink: _goodsLink.default,
    cropUpload: _cropUpload.default
  },
  data: function data() {
    return {
      activeName: "",
      tabList: [],
      dataList: [],
      submitForm: {
        main_title: "",
        sub_title: "",
        background_img: ""
      },
      goodsData: {
        goods_id: null,
        goods_img: "",
        goods_name: "",
        goods_title: "",
        goodsData: null
      }
    };
  },
  computed: {
    configTitle: function configTitle() {
      var _this = this;

      var tab = this.tabList.find(function (item) {
        return item.id == _this.activeName;
      });
      return tab ? tab.main_title : "自定义板块配置";
    }
  },
  methods: {
    // 升序
    upSort: function upSort(index) {
      if (index === 0) {
        return;
      }

      var item = this.dataList[index];
      this.$set(this.dataList, index, this.dataList[index - 1]);
      this.$set(this.dataList, index - 1, item);
    },
    // 降序
    downSort: function downSort(index) {
      if (index === this.dataList.length - 1) {
        return;
      }

      var item = this.dataList[index];
      this.$set(this.dataList, index, this.dataList[index + 1]);
      this.$set(this.dataList, index + 1, item);
    },
    // 删除商品
    deleteGoods: function deleteGoods(index) {
      this.dataList.splice(index, 1);
    },
    // 添加商品
    openSelect: function openSelect() {
      this.$refs.goodsDialog.dialogVisible = true;
      this.$refs.goodsDialog.selectItems = (0, _toConsumableArray2.default)(this.dataList);
    },
    // 设置选中
    setSelect: function setSelect(goodsList) {
      this.dataList = goodsList;
    },
    submit: function submit() {
      var _this2 = this;

      // 校验
      if (!this.submitForm.main_title) {
        this.$message({
          message: "主标题不能为空",
          type: "warning"
        });
        return;
      }

      if (!this.submitForm.sub_title) {
        this.$message({
          message: "副标题不能为空",
          type: "warning"
        });
        return;
      }

      if (!this.submitForm.background_img) {
        this.$message({
          message: "一级推荐位背景图不能为空",
          type: "warning"
        });
        return;
      }

      if (!this.goodsData.goods_img) {
        this.$message({
          message: "一级推荐位商品图片不能为空",
          type: "warning"
        });
        return;
      }

      if (!this.goodsData.goods_name) {
        this.$message({
          message: "一级推荐位商品名称不能为空",
          type: "warning"
        });
        return;
      }

      if (!this.goodsData.goods_id) {
        this.$message({
          message: "一级推荐位请选择商品链接",
          type: "warning"
        });
        return;
      } // 保存


      var goods_arr = this.dataList.map(function (obj) {
        return obj.goods_id;
      });
      var params = (0, _objectSpread2.default)((0, _objectSpread2.default)({
        module_id: this.activeName,
        goodsidStr: goods_arr.join(',')
      }, this.submitForm), this.goodsData);
      delete params.goodsData;
      (0, _recommend.addOrEditCustomModuleGoods)(params).then(function (res) {
        _this2.getData();
      });
    },
    reset: function reset() {
      // 重置
      this.getData();
    },
    handleClick: function handleClick(tab) {
      this.getData();
    },
    // 获取商品列表
    getData: function getData() {
      var _this3 = this;

      (0, _recommend.getCustomModuleGoodsByModuleid)(this.activeName).then(function (res) {
        for (var key in _this3.goodsData) {
          _this3.goodsData[key] = res[key] || "";
        }

        for (var _key in _this3.submitForm) {
          _this3.submitForm[_key] = res[_key] || "";
        }

        _this3.dataList = res.goods;
      });
    },
    getTabs: function getTabs() {
      var _this4 = this;

      (0, _recommend.h5HomeCustomPlateList)().then(function (res) {
        _this4.tabList = res;
        _this4.activeName = _this4.tabList[0].id + "";

        _this4.getData();
      });
    }
  },
  mounted: function mounted() {
    this.getTabs();
  }
};
exports.default = _default;
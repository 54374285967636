"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _system = require("@/api/system");

var _customPage = _interopRequireDefault(require("@/components/customPage"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    customPage: _customPage.default
  },
  props: ['shopname'],
  data: function data() {
    return {
      loading: true,
      searchForm: {
        shopname: "",
        account_num: "",
        status: null
      },
      total: 0,
      tableData: [],
      routerName: ''
    };
  },
  // beforeRouteEnter(to, from, next) {
  // 	if (from.name === "supplierView") {
  // 		next(vm => {
  // 			vm.getList();
  // 		})
  // 	} else {
  // 		next();
  // 	}
  // },
  created: function created() {
    console.log('shopname1', this.$route.query.shopname);

    if (this.$route.query.shopname) {
      this.routerName = this.$route.query.shopname;
    }
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      this.loading = true;
      var _this$$refs$customPag = this.$refs.customPage,
          page = _this$$refs$customPag.page,
          pageSize = _this$$refs$customPag.pageSize;
      (0, _system.getTemplateRuleList)({
        page: page,
        pageSize: pageSize
      }).then(function (res) {
        _this.tableData = res.data || [];
        _this.total = res.total;
        _this.loading = false;
      });
    },
    getTypeName: function getTypeName(type) {
      var str = '';

      switch (type) {
        case 1:
          str = '供应商提醒';
          break;

        case 2:
          str = '用户提醒';
          break;
      }

      return str;
    },
    editTemplate: function editTemplate() {
      var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      this.$router.push({
        name: 'smsView',
        params: {
          viewId: id
        }
      });
    },
    delTemplateRule: function delTemplateRule() {
      var _this2 = this;

      var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      this.$confirm('您确定要删除吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _system.delTemplateRule)(id).then(function (res) {
          _this2.$message.success('操作成功');

          _this2.getList();
        });
      }).catch(function () {});
    },
    // 查询
    search: function search() {
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      this.searchForm = {
        shopname: "",
        account_num: "",
        status: null
      };
      this.routerName = '';
      this.getList();
    }
  }
};
exports.default = _default;
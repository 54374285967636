"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/toConsumableArray"));

require("core-js/modules/es6.array.find");

var _recommend = require("@/api/recommend");

var _goodsLink = _interopRequireDefault(require("../../recommend/compoments/goodsLink"));

var _goodsDialog = _interopRequireDefault(require("@/components/goodsDialog.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    goodsLink: _goodsLink.default,
    goodsDialog: _goodsDialog.default
  },
  data: function data() {
    return {
      activeName: "",
      tabList: [],
      dataList: []
    };
  },
  computed: {
    configTitle: function configTitle() {
      var _this = this;

      var tab = this.tabList.find(function (item) {
        return item.id == _this.activeName;
      });
      return tab ? tab.main_title : "活动板块配置";
    }
  },
  methods: {
    // 升序
    upSort: function upSort(index) {
      if (index === 0) {
        return;
      }

      var item = this.dataList[index];
      this.$set(this.dataList, index, this.dataList[index - 1]);
      this.$set(this.dataList, index - 1, item);
    },
    // 降序
    downSort: function downSort(index) {
      if (index === this.dataList.length - 1) {
        return;
      }

      var item = this.dataList[index];
      this.$set(this.dataList, index, this.dataList[index + 1]);
      this.$set(this.dataList, index + 1, item);
    },
    // 删除商品
    deleteGoods: function deleteGoods(index) {
      this.dataList.splice(index, 1);
    },
    // 设置选中
    setSelect: function setSelect(goodsList) {
      this.dataList = goodsList;
    },
    // 添加商品
    openSelect: function openSelect() {
      this.$refs.goodsDialog.dialogVisible = true;
      this.$refs.goodsDialog.selectItems = (0, _toConsumableArray2.default)(this.dataList);
    },
    submit: function submit() {
      var _this2 = this;

      // 保存
      var list = this.dataList.map(function (obj) {
        return obj.goods_id;
      });
      (0, _recommend.addOrEditActivitiesModuleGoods)({
        module_id: this.activeName,
        goodsidStr: list.join(',')
      }).then(function (res) {
        _this2.getData();
      });
    },
    reset: function reset() {
      // 重置
      this.getData();
    },
    handleClick: function handleClick(tab) {
      this.getData();
    },
    // 获取商品列表
    getData: function getData() {
      var _this3 = this;

      (0, _recommend.getActivitiesModuleGoodsByModuleid)(this.activeName).then(function (res) {
        _this3.dataList = res;
      });
    },
    getTabs: function getTabs() {
      var _this4 = this;

      (0, _recommend.h5HomeActivitiesPlateList)().then(function (res) {
        _this4.tabList = res;
        _this4.activeName = _this4.tabList[0].id + "";

        _this4.getData();
      });
    }
  },
  mounted: function mounted() {
    this.getTabs();
  }
};
exports.default = _default;
"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _goodsLink = _interopRequireDefault(require("../compoments/goodsLink"));

var _goodsDialog = _interopRequireDefault(require("@/components/goodsDialog.vue"));

var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    goodsLink: _goodsLink.default,
    goodsDialog: _goodsDialog.default,
    cropUpload: _cropUpload.default
  },
  props: {
    formData: {
      type: Object,
      default: {}
    },
    showSubtitle: {
      type: Boolean,
      default: true
    },
    showTool: {
      type: Boolean,
      default: true
    },
    showCheckedTool: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      goodsData: null
    };
  },
  methods: {
    // 删除商品链接
    deleteGoods: function deleteGoods() {
      this.$set(this.formData, 'goods_id', null);
      this.$set(this.formData, 'goodsData', null);
    },
    // 设置选中的商品
    setSelect: function setSelect(goodsList) {
      this.$set(this.formData, 'goods_id', goodsList[0].goods_id);
      this.$set(this.formData, 'goodsData', goodsList[0]);
    },
    openSelect: function openSelect() {
      this.$refs.goodsDialog.dialogVisible = true;
    }
  }
};
exports.default = _default;
"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    cropUpload: _cropUpload.default
  },
  props: {
    dialogTitle: {
      type: String
    },
    childVisible: {
      type: Boolean,
      default: false
    },
    childForm: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    firstLevel: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    rent_list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    insure_list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    zlb_list: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  watch: {
    childVisible: function childVisible(val) {
      var _this = this;

      if (val) {
        this.$nextTick(function () {
          _this.$refs.childForm.clearValidate();
        });
      }
    }
  },
  data: function data() {
    var checkNum = function checkNum(rule, value, callback) {
      var reg = /^[0-9]\d*$/;

      if (!value && value !== 0) {
        callback(new Error("请输入数字"));
      } else if (!reg.test(value)) {
        callback(new Error("请输入正整数"));
      } else {
        callback();
      }
    };

    return {
      rules: {
        parentid: [{
          required: true,
          message: "请选择一级品类名称",
          trigger: "change"
        }],
        name: [{
          required: true,
          message: "请输入二级品类名称",
          trigger: "blur"
        }, {
          min: 1,
          max: 10,
          message: "长度在 1 到 10 个字符",
          trigger: "blur"
        }],
        enabled: [{
          required: true,
          message: "请选择状态",
          trigger: "change"
        }],
        link: [{
          required: true,
          message: "请输入链接",
          trigger: "blur"
        }],
        image: [{
          required: true,
          message: "请上传封面",
          trigger: "change"
        }],
        displayorder: [{
          required: true,
          message: "请输入数字",
          trigger: "blur"
        }, {
          validator: checkNum,
          trigger: 'blur'
        }],
        rent_category: [{
          required: true,
          message: "请选择",
          trigger: "change"
        }],
        insurance_category: [{
          required: true,
          message: "请选择",
          trigger: "change"
        }],
        zlb_class: [{
          required: true,
          message: "请选择",
          trigger: "change"
        }]
      }
    };
  },
  methods: {
    closeDialog: function closeDialog(param) {
      this.$emit("closeDialog", param);
    },
    setUrl: function setUrl(val) {
      this.$emit("setUrl", val);
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      var strRegex = '(https?|ftp|file)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]';
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          // var reg = new RegExp(strRegex);
          // if (this.childForm.link) {
          //   if (!reg.test(this.childForm.link)) {
          //     this.$message.error("请确保图片链接正确");
          //     return false;
          //   } else {
          //     this.$emit("submitForm", true);
          //   }
          // }
          _this2.$emit("submitForm", true);
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default2;
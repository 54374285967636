"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _bannerForm = _interopRequireDefault(require("../components/bannerForm"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    bannerForm: _bannerForm.default
  },
  data: function data() {
    return {
      banner: {
        img: '',
        jumpType: '',
        jumpUrl: '',
        goods: {}
      }
    };
  },
  methods: {}
};
exports.default = _default;
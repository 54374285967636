"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.array.sort");

var _user = require("@/api/user");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // components: { addDialog },
  // props: ["id"],
  data: function data() {
    return {
      loading: true,
      id: 0,
      tableData: []
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      (0, _user.getSupplierGlobalConfig)(this.id).then(function (res) {
        _this.tableData = res.config;
        _this.loading = false;
      });
    },
    // 添加下级
    addChildren: function addChildren() {
      var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var time = new Date().getTime();
      var children = {
        id: time,
        key: '',
        title: '',
        sort: 0,
        status: 1,
        lock: 0,
        level: item && item.level > -1 ? item.level + 1 : 0,
        data: '',
        remark: '',
        default: '',
        children: []
      };

      if (item) {
        item.children.push(children);
      } else {
        this.$emit('scrollTo', 'bottom');
        this.tableData.push(children);
      }

      this.$nextTick(function () {
        var el = document.getElementById(time);
        el.focus();
      }); // console.log('add', item, children)
    },
    // 删除
    delChildren: function delChildren(item) {
      var _this2 = this;

      this.$confirm("\u60A8\u786E\u5B9A\u8981\u5220\u9664 ".concat(item.key, ": ").concat(item.remark, " \u5417?"), '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        _this2.tableData = _this2.removeArr(_this2.tableData, item);
      }).catch(function () {});
    },
    // 删除数组
    removeArr: function removeArr() {
      var _this3 = this;

      var list = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var delItem = arguments.length > 1 ? arguments[1] : undefined;
      // console.log('list', list)
      list = list.filter(function (item) {
        var flag = true;

        if (item.level == delItem.level && item.id == delItem.id) {
          flag = false;
        } else if (item.level < delItem.level && item.children.length) {
          item.children = _this3.removeArr(item.children, delItem);
        }

        return flag;
      });
      return list;
    },
    switchStatus: function switchStatus(item) {
      item.status = item.status ? 0 : 1;
    },
    switchLock: function switchLock(item) {
      item.lock = item.lock ? 0 : 1;
    },
    sortConfig: function sortConfig() {
      var _this4 = this;

      var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      config = config ? config : this.tableData;

      if (config.length) {
        config.sort(function (a, b) {
          return b.sort - a.sort;
        });
        config.forEach(function (item) {
          if (item.children) {
            _this4.sortConfig(item.children);
          }
        });
      }
    },
    submit: function submit() {
      var _this5 = this;

      this.loading = true;
      (0, _user.editSupplierGlobalConfig)({
        id: this.id,
        configData: JSON.stringify(this.tableData)
      }).then(function (res) {
        _this5.getList();
      }).catch(function () {});
    }
  }
};
exports.default = _default;
"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _colForm = _interopRequireDefault(require("../components/colForm"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    colForm: _colForm.default
  },
  data: function data() {
    return {
      configData: [{
        backgroundImg: '',
        title: '',
        subtitle: '',
        jumpUrl: ''
      }, {
        backgroundImg: '',
        title: '',
        subtitle: '',
        jumpUrl: ''
      }]
    };
  }
};
exports.default = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

var func = {
  openBlank: function openBlank(url) {
    // let routeData = this.VueRouter.resolve({ path:url}); 
    window.open(url.href, '_blank');
  },
  openWindow: function openWindow(url) {
    // let routeData = this.VueRouter.resolve({ path:url}); 
    window.open(url, '_blank');
  },
  // 深度克隆
  deepClone: function deepClone(source) {
    return JSON.parse(JSON.stringify(source));
  },
  // 整数过滤
  filterNum: function filterNum(val) {
    return val.replace(/[^[0-9-]*/g, '');
  },
  // 浮点数过滤
  filterFloat: function filterFloat(val) {
    return val.replace(/[^[0-9.-]*/g, '');
  }
};
var _default = func;
exports.default = _default;
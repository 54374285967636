"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getMerchantAccount = getMerchantAccount;
exports.getMerchantList = getMerchantList;
exports.getMerchantInfo = getMerchantInfo;
exports.editMerchantInfo = editMerchantInfo;
exports.editMerchantCategoryIds = editMerchantCategoryIds;
exports.getMerchantOnceToken = getMerchantOnceToken;
exports.getMerchantAdminGruopList = getMerchantAdminGruopList;
exports.editMerchantAdminGruop = editMerchantAdminGruop;
exports.getEsignOrgId = getEsignOrgId;
exports.getEsignSealId = getEsignSealId;
exports.getBindSupplierList = getBindSupplierList;
exports.saveBindSupplier = saveBindSupplier;
exports.delBindSupplier = delBindSupplier;
exports.setMerchant = setMerchant;

var _fetch = _interopRequireDefault(require("@/utils/fetch"));

// // 获取省份列表
// export function getProvinceList() {
//   return http.get("/Admin/Supplier/getCompanyArea");
// }
// // 获取区域二级列表
// export function getCompanyArea(id) {
//   return http.get("/Admin/Supplier/getSecondCompanyArea", { id, id });
// }

/**
 * 商户管理
 */
// 获取商户账号信息
function getMerchantAccount(id) {
  return _fetch.default.post("/Admin/Merchant/getMerchantAccount", {
    id: id
  });
} // 获取商户账号列表


function getMerchantList(params) {
  return _fetch.default.post("/Admin/Merchant/getMerchantList", params);
} // 查看商户信息


function getMerchantInfo(id) {
  return _fetch.default.get("/Admin/Merchant/getMerchantInfo", {
    id: id
  });
} // 编辑商户信息


function editMerchantInfo(params) {
  return _fetch.default.post("/Admin/Merchant/editMerchantInfo", params, true);
} // 编辑商户经营项目


function editMerchantCategoryIds(params) {
  return _fetch.default.post("/Admin/Merchant/editMerchantCategoryIds", params);
} // 获取商户一次性登录token


function getMerchantOnceToken(id) {
  return _fetch.default.post("/Admin/getMerchantOnceToken", {
    id: id
  });
} // 获取商户管理员组权限


function getMerchantAdminGruopList(params) {
  return _fetch.default.post("/Admin/Merchant/getMerchantAdminGruopList", params);
} // 编辑商户管理员组权限


function editMerchantAdminGruop(params) {
  return _fetch.default.post("/Admin/Merchant/editMerchantAdminGruop", params, true);
}
/**
 * e签宝认证
 */
// 获取e签宝psn_id
// export function getEsignPsnId(id) {
// 	return http.post("/Admin/Merchant/getEsignPsnId", { id }, true);
// }
// 获取e签宝org_id


function getEsignOrgId(param) {
  return _fetch.default.post("/Admin/Merchant/getEsignOrgId", param, true);
} // 获取e签宝seal_id


function getEsignSealId(param) {
  return _fetch.default.post("/Admin/Merchant/getEsignSealId", param, true);
}
/**
 * 供应商绑定
 */
// 获取绑定供应商列表


function getBindSupplierList(params) {
  return _fetch.default.post("/Admin/Merchant/getBindSupplierList", params);
} // 保存绑定供应商


function saveBindSupplier(params) {
  return _fetch.default.post("/Admin/Merchant/saveBindSupplier", params, true);
} // 删除绑定供应商


function delBindSupplier(params) {
  return _fetch.default.post("/Admin/Merchant/delBindSupplier", params, true);
}
/**
 * 商户管理
 */
// 批量操作设置状态


function setMerchant(params) {
  return _fetch.default.post("/Supplier/Auth/setMerchant", params, true);
}
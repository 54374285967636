"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _customPage = _interopRequireDefault(require("@/components/customPage"));

var _audit = require("@/api/audit");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    customPage: _customPage.default
  },
  props: ['goodsId'],
  data: function data() {
    return {
      tableData: [],
      goodsName: ""
    };
  },
  methods: {
    getList: function getList() {
      var _this = this;

      (0, _audit.getGoodsSetmealList)(this.goodsId).then(function (res) {
        _this.goodsName = res.goods_name;
        _this.tableData = res.setmeal || [];
        _this.$route.meta.title = _this.goodsName + '的套餐列表';
      });
    }
  },
  mounted: function mounted() {
    this.getList();
  }
};
exports.default = _default;
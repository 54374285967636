"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getGoodsDetails = getGoodsDetails;

var _fetch = _interopRequireDefault(require("@/utils/fetch"));

function getGoodsDetails(params) {
  return _fetch.default.get("/Antchain/goods/info", params);
}
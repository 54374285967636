"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _ipBlockConfig = _interopRequireDefault(require("./child/ipBlockConfig"));

var _ipBlockList = _interopRequireDefault(require("./child/ipBlockList"));

var _ipVisitList = _interopRequireDefault(require("./child/ipVisitList"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    ipBlockConfig: _ipBlockConfig.default,
    ipBlockList: _ipBlockList.default,
    ipVisitList: _ipVisitList.default
  },
  data: function data() {
    return {
      activeName: "ipBlockList",
      activeId: 0,
      tabList: [{
        id: 0,
        name: "ipBlockList",
        label: "IP访问列表"
      }, {
        id: 1,
        name: "ipBlockConfig",
        label: "IP封禁管理"
      }, {
        id: 2,
        name: "ipVisitList",
        label: "IP白名单"
      }]
    };
  },
  mounted: function mounted() {},
  methods: {
    handleClick: function handleClick(tab) {// this.activeName = tab.name
    }
  }
};
exports.default = _default;
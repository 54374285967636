"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/objectSpread2"));

var _complaint = require("@/api/complaint");

var _customPage = _interopRequireDefault(require("@/components/customPage"));

var _myDatePicker = _interopRequireDefault(require("@/components/myDatePicker"));

var _dispose = _interopRequireDefault(require("./dailog/dispose"));

var _noteRecords = _interopRequireDefault(require("./dailog/noteRecords"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    customPage: _customPage.default,
    myDatePicker: _myDatePicker.default,
    noteRecords: _noteRecords.default,
    dispose: _dispose.default
  },
  data: function data() {
    return {
      searchForm: {
        work_order: "",
        suggest_type: "",
        status: "",
        grade: "",
        create_time: ""
      },
      activeName: "0",
      suggestId: "",
      count: {
        grade_total: 0,
        ordinary_grade_total: 0,
        urgent_grade_total: 0,
        serious_grade_total: 0
      },
      typeList: [],
      total: 0,
      tableData: []
    };
  },
  methods: {
    check: function check(id) {
      this.suggestId = id;
      this.$router.push({
        name: "complaintDetail",
        params: {
          id: id
        }
      });
    },
    intervene: function intervene(id) {
      this.suggestId = id;
      this.$refs.disposes.dialogVisible = true;
    },
    remark: function remark(id) {
      this.suggestId = id;
      this.$refs.records.dialogVisible = true;
    },
    getType: function getType() {
      var _this = this;

      (0, _complaint.getSuggestType)().then(function (res) {
        _this.typeList = res;
      });
    },
    // 获取数据
    getList: function getList(flag) {
      var _this2 = this;

      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
          page = _this$$refs$customPag.page,
          pageSize = _this$$refs$customPag.pageSize;

      if (!flag) {
        this.tableData = [];
      }

      (0, _complaint.complaintList)((0, _objectSpread2.default)((0, _objectSpread2.default)({
        page: page,
        pageSize: pageSize
      }, this.searchForm), {}, {
        grade: this.activeName
      })).then(function (res) {
        _this2.tableData = res.data || [];
        _this2.total = res.total;

        for (var key in _this2.count) {
          _this2.count[key] = res.count[key];
        }
      });
    },
    // 查询
    search: function search() {
      var pickerVal = this.$refs.myDatePicker.getVal();

      if (pickerVal !== "error") {
        this.searchForm.create_time = pickerVal;
      }

      this.$refs.customPage.page = 1;
      this.getList();
    },
    // selectGrade(val){
    //   console.log('val',val)
    //   this.searchForm.grade = val
    // },
    // 重置
    reset: function reset() {
      if (this.$refs.myDatePicker) {
        this.$refs.myDatePicker.reset();
      }

      this.searchForm = {
        work_order: "",
        suggest_type: "",
        status: "",
        grade: ""
      };
      this.getList();
    },
    handleClick: function handleClick(tab) {
      this.activeName = tab.name;
      this.$refs.customPage.page = 1;
      this.getList();
    }
  },
  mounted: function mounted() {
    this.getType();
    this.getList();
  }
};
exports.default = _default;
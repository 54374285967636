"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetRouter = resetRouter;
exports.default = exports.asyncRoutes = exports.constantRoutes = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireWildcard"));

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

var _layout = _interopRequireDefault(require("@/layout"));

_vue.default.use(_vueRouter.default);
/* Layout */


/* 静态路由 */
var constantRoutes = [{
  path: "/login",
  name: "login",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/login/index"));
    });
  },
  hidden: true
}, {
  path: "/editPwd",
  name: "editPwd",
  component: _layout.default,
  hidden: true
}, {
  path: "/trans",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/trans"));
    });
  },
  hidden: true
}, {
  path: "/401",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error-page/401"));
    });
  },
  hidden: true
}, {
  path: "/404",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error-page/404"));
    });
  },
  hidden: true
}, {
  path: "/",
  component: _layout.default,
  redirect: "/trans",
  hidden: true
}, {
  path: "/logisticsH5",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/skipPage/logisticsInfo/index.vue"));
    });
  },
  hidden: true
}, {
  path: "/goodsDetails",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/skipPage/goodsDetails/index.vue"));
    });
  },
  hidden: true
}];
/* 动态路由 */

exports.constantRoutes = constantRoutes;
var asyncRoutes = [{
  path: "/xcxPos",
  name: "xcxPos",
  component: _layout.default,
  redirect: "/xcxPos/home",
  meta: {
    title: "推荐位管理",
    icon: "position"
  },
  children: [{
    path: "home",
    name: "xcxPosHome",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/xcxPos/home/index"));
      });
    },
    meta: {
      title: "首页推荐位",
      icon: ""
    }
  }, {
    path: "preview",
    name: "xcxPosPreview",
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/xcxPos/home/preview"));
      });
    },
    meta: {
      title: "小程序预览",
      icon: "",
      activeMenu: "/xcxPos/home",
      parentTitle: "首页推荐位",
      parentRouteName: "xcxPosHome"
    }
  }]
}, {
  path: "/webConfig",
  name: "webConfig",
  component: _layout.default,
  redirect: "/webConfig/category",
  meta: {
    title: "PC端配置",
    icon: "config"
  },
  children: [{
    path: "category",
    name: "webConfigCategory",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/web/category/index"));
      });
    },
    meta: {
      title: "商品首页品类板块",
      icon: ""
    }
  }, {
    path: "category-add/:type/:editId?",
    name: "webConfigCategoryAdd",
    hidden: true,
    props: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/web/category/add"));
      });
    },
    meta: {
      title: "添加品类模板",
      icon: "",
      activeMenu: "/webConfig/category",
      parentTitle: "商品首页品类板块",
      parentRouteName: "webConfigCategory"
    }
  }, {
    path: "show",
    name: "webConfigShow",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/web/show/index"));
      });
    },
    meta: {
      title: "商品首页展示板块",
      icon: ""
    }
  }, {
    path: "show-add/:editId?",
    name: "webConfigShowAdd",
    hidden: true,
    props: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/web/show/add"));
      });
    },
    meta: {
      title: "添加板块",
      icon: "",
      activeMenu: "/webConfig/show",
      parentTitle: "商品首页展示板块",
      parentRouteName: "webConfigShow"
    }
  }]
}, {
  path: "/article",
  name: "article",
  component: _layout.default,
  redirect: "/article/platform",
  meta: {
    title: "文章信息管理",
    icon: "info"
  },
  children: [{
    path: "platform",
    name: "articlePlatform",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/article/index"));
      });
    },
    meta: {
      title: "文章信息列表",
      icon: ""
    }
  }, {
    path: "platform-add/:type/:editId?",
    name: "articlePlatformAdd",
    hidden: true,
    props: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/article/add"));
      });
    },
    meta: {
      title: "平台发布文章",
      icon: "",
      activeMenu: "/article/platform",
      parentTitle: "平台文章列表",
      parentRouteName: "articlePlatform"
    }
  }, {
    path: "info",
    name: "articleInfoConfig",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/web/info/index"));
      });
    },
    meta: {
      title: "信息配置",
      icon: ""
    }
  }, {
    path: "info-add/:parentId?/:editId?",
    name: "articleInfoConfigAdd",
    hidden: true,
    props: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/web/info/add"));
      });
    },
    meta: {
      title: "添加信息",
      icon: "",
      activeMenu: "/webConfig/info",
      parentTitle: "信息配置",
      parentRouteName: "articleInfoConfig"
    }
  }]
}, {
  path: "/recommend",
  name: "recommend",
  component: _layout.default,
  redirect: "/recommend/banner",
  meta: {
    title: "PC商城推荐位管理",
    icon: "position"
  },
  children: [{
    path: "banner",
    name: "recommendBanner",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/recommend/banner/index"));
      });
    },
    meta: {
      title: "商城banner设置",
      icon: ""
    }
  }, {
    path: "category",
    name: "recommendCategory",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/recommend/category/index"));
      });
    },
    meta: {
      title: "首页品类商品推荐",
      icon: ""
    }
  }, {
    path: "activity",
    name: "recommendActivity",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/recommend/activity/index"));
      });
    },
    meta: {
      title: "首页活动板块",
      icon: ""
    }
  }, {
    path: "custom",
    name: "recommendCustom",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/recommend/custom/index"));
      });
    },
    meta: {
      title: "首页自定义板块",
      icon: ""
    }
  }]
}, {
  path: "/account",
  name: "account",
  component: _layout.default,
  redirect: "/account/list",
  meta: {
    title: "账号管理",
    icon: "user"
  },
  children: [{
    path: "list",
    name: "accountList",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/system/account/index"));
      });
    },
    meta: {
      title: "账号列表",
      icon: ""
    }
  }, {
    path: "role",
    name: "accountRole",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/system/role/index"));
      });
    },
    meta: {
      title: "角色列表",
      icon: ""
    }
  }]
}, // {
// 	path: "/permission",
// 	name: "permission",
// 	component: Layout,
// 	redirect: "/permission/role",
// 	meta: { title: "权限管理", icon: "permission" },
// 	children: [
// 		{
// 			path: "role",
// 			name: "permissionRole",
// 			component: () => import("@/views/system/role/index"),
// 			meta: { title: "角色列表", icon: "" },
// 		},
// 	],
// },
{
  path: "/menu",
  name: "menu",
  component: _layout.default,
  redirect: "/menu/list",
  meta: {
    title: "菜单管理",
    icon: "set"
  },
  children: [{
    path: "list",
    name: "menuList",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/system/menu/index"));
      });
    },
    meta: {
      title: "菜单列表",
      icon: ""
    }
  }]
}, {
  path: "/sms",
  name: "sms",
  component: _layout.default,
  redirect: "/sms/list",
  meta: {
    title: "短信管理",
    icon: "el-icon-message"
  },
  children: [{
    path: "list",
    name: "smsList",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/system/sms/index"));
      });
    },
    meta: {
      title: "模板列表",
      icon: ""
    }
  }, {
    path: "view/:viewId",
    name: "smsView",
    props: true,
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/system/sms/view"));
      });
    },
    meta: {
      title: "模板详情",
      icon: "",
      activeMenu: "/sms/list",
      parentTitle: "模板列表",
      parentRouteName: "smsList"
    }
  }]
}, {
  path: "/safe",
  name: "safe",
  component: _layout.default,
  redirect: "/safe/ip",
  meta: {
    title: "安全管理",
    icon: "el-icon-message"
  },
  children: [{
    path: "ip",
    name: "safeIp",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/system/safe/ip"));
      });
    },
    meta: {
      title: "IP管理",
      icon: ""
    }
  }]
}, {
  path: "/payment",
  name: "payment",
  component: _layout.default,
  redirect: "/payment/list",
  meta: {
    title: "支付管理",
    icon: "el-icon-message"
  },
  children: [{
    path: "list",
    name: "paymentList",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/system/payment/index"));
      });
    },
    meta: {
      title: "支付通道列表",
      icon: ""
    }
  }]
}, {
  path: "/supplier",
  name: "supplier",
  component: _layout.default,
  redirect: "/supplier/list",
  meta: {
    title: "供应商管理",
    icon: "user"
  },
  children: [{
    path: "list",
    name: "supplierList",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/supplier/index"));
      });
    },
    meta: {
      title: "供应商列表",
      icon: "",
      keepAlive: true
    }
  }, {
    path: "mainConfig",
    name: "supplierMainConfig",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/supplier/mainConfig"));
      });
    },
    meta: {
      title: "全局配置",
      icon: ""
    }
  }, {
    path: "view/:viewId/:toEdit?",
    name: "supplierView",
    props: true,
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/supplier/view"));
      });
    },
    meta: {
      title: "供应商信息",
      icon: "",
      activeMenu: "/supplier/list",
      parentTitle: "供应商列表",
      parentRouteName: "supplierList"
    }
  }, {
    path: "config/:id",
    name: "supplierConfig",
    props: true,
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/supplier/config"));
      });
    },
    meta: {
      title: "供应商配置",
      icon: "",
      activeMenu: "/supplier/list",
      parentTitle: "供应商列表",
      parentRouteName: "supplierList"
    }
  }, {
    path: "vip/:id",
    name: "supplierOpenVip",
    props: true,
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/supplier/vip"));
      });
    },
    meta: {
      title: "开启会员卡模板",
      icon: "",
      activeMenu: "/supplier/list",
      parentTitle: "供应商列表",
      parentRouteName: "supplierList"
    }
  }, {
    path: "copygoods/:id",
    name: "supplierCopyGoods",
    props: true,
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/supplier/copygoods"));
      });
    },
    meta: {
      title: "复制商品",
      icon: "",
      activeMenu: "/supplier/list",
      parentTitle: "供应商列表",
      parentRouteName: "supplierList"
    }
  }, {
    path: "mayiplatadd/:id",
    name: "supplierMayiPlatAdd",
    props: true,
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/supplier/mayiplatadd"));
      });
    },
    meta: {
      title: "蚂蚁平台用户注册",
      icon: "",
      activeMenu: "/supplier/list",
      parentTitle: "供应商列表",
      parentRouteName: "supplierList"
    }
  }, {
    path: "changePwd/:id",
    name: "supplierChangePwd",
    props: true,
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/supplier/changePwd"));
      });
    },
    meta: {
      title: "修改供应商密码",
      icon: "",
      activeMenu: "/supplier/list",
      parentTitle: "供应商列表",
      parentRouteName: "supplierList"
    }
  }, {
    path: "mayimerapply/:id",
    name: "supplierMayiMerchantApply",
    props: true,
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/supplier/mayimerapply"));
      });
    },
    meta: {
      title: "蚂蚁公司收款设置",
      icon: "",
      activeMenu: "/supplier/list",
      parentTitle: "供应商列表",
      parentRouteName: "supplierList"
    }
  }]
}, {
  path: "/merchantAccount",
  name: "merchantAccount",
  component: _layout.default,
  redirect: "/merchantAccount/list",
  meta: {
    title: "商户管理",
    icon: "user"
  },
  children: [{
    path: "list",
    name: "merchantAccountList",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/system/merchant/index"));
      });
    },
    meta: {
      title: "商户列表",
      icon: ""
    }
  }, {
    path: "view/:viewId/:toEdit?",
    name: "merchantAccountView",
    hidden: true,
    props: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/system/merchant/view"));
      });
    },
    meta: {
      title: "商户详情",
      icon: "",
      activeMenu: "/merchantAccount/list",
      parentTitle: "商户列表",
      parentRouteName: "merchantAccountList"
    }
  }]
}, {
  path: "/member",
  name: "member",
  component: _layout.default,
  redirect: "/member/list",
  meta: {
    title: "用户管理",
    icon: "user"
  },
  children: [{
    path: "list",
    name: "memberList",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/member"));
      });
    },
    meta: {
      title: "用户列表",
      icon: "",
      keepAlive: true
    }
  }, {
    path: "view/:viewId/:toEdit?",
    name: "memberView",
    props: true,
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/member/view"));
      });
    },
    meta: {
      title: "用户信息",
      icon: "",
      activeMenu: "/member/list",
      parentTitle: "用户列表",
      parentRouteName: "memberList"
    }
  }]
}, {
  path: "/goods",
  name: "goods",
  component: _layout.default,
  redirect: "/goods/list",
  meta: {
    title: "商品管理",
    icon: "product"
  },
  children: [{
    path: "list",
    name: "goodsList",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/goods/index"));
      });
    },
    meta: {
      title: "商品列表",
      icon: "",
      keepAlive: true
    }
  }, {
    path: "editBase/:editId",
    name: "goodsBaseEdit",
    hidden: true,
    props: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/goods/editBase"));
      });
    },
    meta: {
      title: "编辑商品信息",
      icon: "",
      activeMenu: "/goods/list",
      parentTitle: "商品列表",
      parentRouteName: "goodsList"
    }
  }, {
    path: "mealInfo/:goodsId",
    name: "goodsMealInfo",
    hidden: true,
    props: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/goods/mealList"));
      });
    },
    meta: {
      title: "套餐信息",
      icon: "",
      activeMenu: "/goods/list",
      parentTitle: "商品列表",
      parentRouteName: "goodsList"
    }
  }, {
    path: "success/:goodsId",
    name: "goodsSuccess",
    hidden: true,
    props: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/goods/success"));
      });
    },
    meta: {
      title: "编辑商品",
      icon: "",
      activeMenu: "/goods/list",
      parentTitle: "商品列表",
      parentRouteName: "goodsList"
    }
  }, {
    path: "editMeal/:goodsId/:editId",
    name: "goodsMealEdit",
    hidden: true,
    props: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/goods/editMeal"));
      });
    },
    meta: {
      title: "编辑套餐",
      icon: "",
      activeMenu: "/goods/list",
      parentTitle: "商品列表",
      parentRouteName: "goodsList"
    }
  }, {
    path: "view/:goodsId",
    name: "goodsView",
    hidden: true,
    props: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/goods/view"));
      });
    },
    meta: {
      title: "查看商品",
      icon: "",
      activeMenu: "/goods/list",
      parentTitle: "商品列表",
      parentRouteName: "goodsList"
    }
  }]
}, {
  path: "/audit",
  name: "audit",
  component: _layout.default,
  redirect: "/audit/category",
  meta: {
    title: "审核管理",
    icon: "audit"
  },
  children: [{
    path: "category",
    name: "auditCategory",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/audit/category/index"));
      });
    },
    meta: {
      title: "品类审核",
      icon: ""
    }
  }, {
    path: "product",
    name: "auditGoods",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/audit/goods/index"));
      });
    },
    meta: {
      title: "商品审核",
      icon: "",
      keepAlive: true
    }
  }, {
    path: "offGoods",
    name: "auditOffGoods",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/audit/goods/off"));
      });
    },
    meta: {
      title: "商品下架审核",
      icon: "",
      keepAlive: true
    }
  }, {
    path: "view/:goodsId",
    name: "auditViewGoods",
    hidden: true,
    props: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/goods/view"));
      });
    },
    meta: {
      title: "查看商品",
      icon: "",
      activeMenu: "/audit/product",
      parentTitle: "商品审核",
      parentRouteName: "auditGoods"
    }
  }, {
    path: "check/:goodsId",
    name: "auditCheckGoods",
    hidden: true,
    props: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/goods/view"));
      });
    },
    meta: {
      title: "查看商品",
      icon: "",
      activeMenu: "/audit/offGoods",
      parentTitle: "商品下架审核",
      parentRouteName: "auditCheckGoods"
    }
  }, {
    path: "supplier",
    name: "auditSupplier",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/audit/supplier/index"));
      });
    },
    meta: {
      title: "供应商审核",
      icon: "",
      keepAlive: true
    }
  }, {
    path: "supplierView/:viewId/:toEdit?",
    name: "auditSupplierView",
    props: true,
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/audit/supplier/view"));
      });
    },
    meta: {
      title: "查看供应商资料",
      icon: "",
      activeMenu: "/audit/supplier",
      parentTitle: "供应商审核",
      parentRouteName: "auditSupplier"
    }
  }]
}, {
  path: "/finance",
  name: "finance",
  component: _layout.default,
  redirect: "/finance/billing",
  meta: {
    title: "财务审核",
    icon: "refund"
  },
  children: [{
    path: "billing",
    name: "financeBilling",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/finance/billing/index"));
      });
    },
    meta: {
      title: "账单结算",
      icon: ""
    }
  }, {
    path: "payBill",
    name: "financePayBill",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/finance/payBill/index"));
      });
    },
    meta: {
      title: "花呗账单结算",
      icon: ""
    }
  }, {
    path: "refundAudit",
    name: "financeRefund",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/finance/refundAudit/index"));
      });
    },
    meta: {
      title: "退款审核",
      icon: ""
    }
  }, {
    path: "details/:orderId",
    name: "financeRefundDetail",
    hidden: true,
    props: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/finance/refundAudit/details"));
      });
    },
    meta: {
      title: "退款审核",
      icon: "",
      activeMenu: "/finance/refundAudit",
      parentTitle: "退款审核",
      parentRouteName: "finance"
    }
  }, {
    path: "withdrawal",
    name: "financeWithdrawal",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/finance/withdrawal/index"));
      });
    },
    meta: {
      title: "提现审核",
      icon: ""
    }
  }, {
    path: "merchantWithdrawal",
    name: "financeMerchantWithdrawal",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/finance/merchantWithdrawal/index"));
      });
    },
    meta: {
      title: "商户提现审核",
      icon: ""
    }
  } // {
  //   path: "view/:goodsId",
  //   name: "auditViewGoods",
  //   hidden: true,
  //   props: true,
  //   component: () => import("@/views/goods/view"),
  //   meta: {
  //     title: "查看商品",
  //     icon: "",
  //     activeMenu: "/audit/product",
  //     parentTitle: "商品审核",
  //     parentRouteName: "auditGoods",
  //   },
  // },
  ]
}, {
  path: "/info",
  name: "info",
  component: _layout.default,
  redirect: "/info/list",
  meta: {
    title: "基础信息管理",
    icon: "category"
  },
  children: [{
    path: "list",
    name: "infoCategoryList",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/info/list/index"));
      });
    },
    meta: {
      title: "品类列表",
      icon: ""
    }
  }, {
    path: "custom",
    name: "infoDefineForm",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/info/custom/index"));
      });
    },
    meta: {
      title: "定义表单项",
      icon: ""
    }
  }, {
    path: "source",
    name: "infoOrderSource",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/info/source/index"));
      });
    },
    meta: {
      title: "订单来源",
      icon: ""
    }
  }, {
    path: "link",
    name: "infoAliAppLink",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/info/aliAppLink/index"));
      });
    },
    meta: {
      title: "小程序链接",
      icon: ""
    }
  }]
}, {
  path: "/lease",
  name: "lease",
  component: _layout.default,
  redirect: "/lease/user",
  meta: {
    title: "租赁宝管理",
    icon: "order"
  },
  children: [{
    path: "user",
    name: "leaseUser",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/lease/user"));
      });
    },
    meta: {
      title: "订单用户信息",
      icon: ""
    }
  }, {
    path: "order",
    name: "leaseOrder",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/lease/order"));
      });
    },
    meta: {
      title: "订单信息",
      icon: ""
    }
  }, {
    path: "product",
    name: "leaseGoods",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/lease/goods"));
      });
    },
    meta: {
      title: "订单商品信息",
      icon: ""
    }
  }, {
    path: "logistics",
    name: "leaseLogistics",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/lease/logistics"));
      });
    },
    meta: {
      title: "订单物流信息",
      icon: ""
    }
  }, {
    path: "promise",
    name: "leasePromise",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/lease/promise"));
      });
    },
    meta: {
      title: "订单承诺信息",
      icon: ""
    }
  }, {
    path: "appoint",
    name: "leaseAppoint",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/lease/appoint"));
      });
    },
    meta: {
      title: "订单履约信息",
      icon: ""
    }
  }, {
    path: "financing",
    name: "leaseFinanc",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/lease/financing"));
      });
    },
    meta: {
      title: "订单查询",
      icon: ""
    }
  }, {
    path: "check/:orderNo",
    name: "leaseCheckFinanc",
    hidden: true,
    props: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/lease/child/financDetail"));
      });
    },
    meta: {
      title: "租赁宝订单详情",
      icon: "",
      activeMenu: "/lease/financing",
      parentTitle: "订单查询",
      parentRouteName: "leaseFinanc"
    }
  }]
}, {
  path: "/popularize",
  name: "popularize",
  component: _layout.default,
  redirect: "/popularize/classify",
  meta: {
    title: "商品推广管理",
    icon: "audit"
  },
  children: [{
    path: "classify",
    name: "popularizeClassify",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/popularize/classify"));
      });
    },
    meta: {
      title: "商品推广分类列表",
      icon: ""
    }
  }, {
    path: "list",
    name: "popularizeList",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/popularize/list"));
      });
    },
    meta: {
      title: "商品推广列表",
      icon: ""
    }
  }]
}, {
  path: "/order",
  name: "order",
  component: _layout.default,
  redirect: "/order/list",
  meta: {
    title: "订单管理",
    icon: "order"
  },
  children: [{
    path: "list",
    name: "orderList",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order/list"));
      });
    },
    meta: {
      title: "订单列表",
      icon: ""
    }
  }, {
    path: "hb",
    name: "orderHb",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order/hb"));
      });
    },
    meta: {
      title: "花呗订单",
      icon: ""
    }
  }, {
    path: "buyout",
    name: "orderBuyout",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order/buyout"));
      });
    },
    meta: {
      title: "买断订单",
      icon: ""
    }
  }, {
    path: "unpay",
    name: "orderUnpay",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order/unpay"));
      });
    },
    meta: {
      title: "签约待支付订单",
      icon: ""
    }
  }, {
    path: "relet",
    name: "orderRelet",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order/relet"));
      });
    },
    meta: {
      title: "续租订单",
      icon: ""
    }
  }, {
    path: "risk",
    name: "orderRisk",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order/risk"));
      });
    },
    meta: {
      title: "风控订单",
      icon: ""
    }
  }]
}, {
  path: "/refund",
  name: "refund",
  component: _layout.default,
  redirect: "/refund/list",
  meta: {
    title: "退款管理",
    icon: "refund"
  },
  children: [{
    path: "list",
    name: "refundList",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/refund/list"));
      });
    },
    meta: {
      title: "仅退款列表",
      icon: ""
    }
  }, {
    path: "refundDetails/:id",
    name: "refundDetail",
    hidden: true,
    props: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/refund/refundDetails"));
      });
    },
    meta: {
      title: "退款详情",
      icon: "",
      activeMenu: "/refund/list",
      parentTitle: "仅退款列表",
      parentRouteName: "refund"
    }
  }]
}, {
  path: "/thirdData",
  name: "thirdData",
  component: _layout.default,
  redirect: "/thirdData/message",
  meta: {
    title: "第三方数据",
    icon: "data"
  },
  children: [{
    path: "message",
    name: "thirdDataMessage",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/thirdData/message"));
      });
    },
    meta: {
      title: "短信使用列表",
      icon: ""
    }
  }, {
    path: "risk",
    name: "thirdDataRisk",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/thirdData/risk"));
      });
    },
    meta: {
      title: "风控使用列表",
      icon: ""
    }
  }]
}, {
  path: "/complaint",
  name: "complaint",
  component: _layout.default,
  redirect: "/complaint/list",
  meta: {
    title: "投诉与建议",
    icon: "user"
  },
  children: [{
    path: "list",
    name: "complaintList",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/complaint/index"));
      });
    },
    meta: {
      title: "投诉列表",
      icon: ""
    }
  }, {
    path: "complaintDetails/:id",
    name: "complaintDetail",
    hidden: true,
    props: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/complaint/complaintDetails"));
      });
    },
    meta: {
      title: "查看详情",
      icon: "",
      activeMenu: "/complaint/list",
      parentTitle: "投诉列表",
      parentRouteName: "complaint"
    }
  }]
}, {
  path: "/bill",
  name: "bill",
  component: _layout.default,
  redirect: "/bill/list",
  meta: {
    title: "账单管理",
    icon: "bill"
  },
  children: [{
    path: "list",
    name: "billList",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/bill/index"));
      });
    },
    meta: {
      title: "订单财务列表",
      icon: ""
    }
  }, {
    path: "bill",
    name: "billBill",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/bill/bill"));
      });
    },
    meta: {
      title: "账单财务列表",
      icon: ""
    }
  }, {
    path: "hb",
    name: "billHb",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/bill/hb"));
      });
    },
    meta: {
      title: "花呗分期账单",
      icon: ""
    }
  }, {
    path: "device",
    name: "billDevice",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/bill/device"));
      });
    },
    meta: {
      title: "设备分期导出",
      icon: ""
    }
  }, {
    path: "payment",
    name: "billPayment",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/bill/payment"));
      });
    },
    meta: {
      title: "回款导出",
      icon: ""
    }
  }]
}, {
  path: "/rate",
  name: "rate",
  component: _layout.default,
  redirect: "/rate/list",
  meta: {
    title: "扣费管理",
    icon: "bill"
  },
  children: [{
    path: "list",
    name: "rateList",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/rate/index"));
      });
    },
    meta: {
      title: "扣费列表",
      icon: ""
    }
  }]
}, {
  path: "/overdue",
  name: "overdue",
  component: _layout.default,
  redirect: "/overdue/list",
  meta: {
    title: "逾期管理",
    icon: "overdue"
  },
  children: [{
    path: "list",
    name: "overdueList",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/overdue/index"));
      });
    },
    meta: {
      title: "逾期列表",
      icon: ""
    }
  }]
}, {
  path: "/reconciliation",
  name: "reconciliation",
  component: _layout.default,
  redirect: "/reconciliation/withdrawal",
  meta: {
    title: "对账中心",
    icon: "bill"
  },
  children: [{
    path: "flowNew",
    name: "reconciliationFlow",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/reconciliation/flowNew"));
      });
    },
    meta: {
      title: "对账流水",
      icon: ""
    }
  }, {
    path: "withdrawal",
    name: "reconciliationWithdrawal",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/reconciliation/withdrawal"));
      });
    },
    meta: {
      title: "提现记录",
      icon: ""
    }
  }]
}, {
  path: "/nine",
  name: "nine",
  component: _layout.default,
  redirect: "/nine/office",
  meta: {
    title: "9大分类管理",
    icon: "position"
  },
  children: [{
    path: "office",
    name: "nineOffice",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/nine/index"));
      });
    },
    meta: {
      title: "办公设备",
      icon: ""
    }
  }, {
    path: "computer",
    name: "nineComputer",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/nine/index"));
      });
    },
    meta: {
      title: "电脑平板",
      icon: ""
    }
  }, {
    path: "health",
    name: "nineHealth",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/nine/index"));
      });
    },
    meta: {
      title: "健康理疗",
      icon: ""
    }
  }, {
    path: "mobile",
    name: "nineMobile",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/nine/index"));
      });
    },
    meta: {
      title: "品牌手机",
      icon: ""
    }
  }, {
    path: "electric",
    name: "nineElectric",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/nine/index"));
      });
    },
    meta: {
      title: "能源设备",
      icon: ""
    }
  }, {
    path: "energy",
    name: "nineEnergy",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/nine/index"));
      });
    },
    meta: {
      title: "开店赋能",
      icon: ""
    }
  }, {
    path: "camera",
    name: "nineCamera",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/nine/index"));
      });
    },
    meta: {
      title: "数码摄影",
      icon: ""
    }
  }, {
    path: "game",
    name: "nineGame",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/nine/index"));
      });
    },
    meta: {
      title: "游戏设备",
      icon: ""
    }
  }, {
    path: "home",
    name: "nineHome",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/nine/index"));
      });
    },
    meta: {
      title: "智能家居",
      icon: ""
    }
  }]
}, {
  path: "/activity",
  name: "activity",
  component: _layout.default,
  redirect: "/activity/hb",
  meta: {
    title: "活动专区管理",
    icon: "position"
  },
  children: [{
    path: "hb",
    name: "activityHb",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/nine/index"));
      });
    },
    meta: {
      title: "花呗专区",
      icon: ""
    }
  }, {
    path: "hot",
    name: "activityHot",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/nine/index"));
      });
    },
    meta: {
      title: "热租爆品",
      icon: ""
    }
  }, {
    path: "short",
    name: "activityShort",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/nine/index"));
      });
    },
    meta: {
      title: "短租精选",
      icon: ""
    }
  }, {
    path: "rent",
    name: "activityRent",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/nine/index"));
      });
    },
    meta: {
      title: "租完即送",
      icon: ""
    }
  }]
}, {
  path: "/h5Config",
  name: "h5Config",
  component: _layout.default,
  redirect: "/h5Config/category",
  meta: {
    title: "H5端配置",
    icon: "audit"
  },
  children: [{
    path: "category",
    name: "h5ConfigCategory",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/h5/category/index"));
      });
    },
    meta: {
      title: "首页品类板块",
      icon: ""
    }
  }, {
    path: "categoryAdd/:type/:editId?",
    name: "h5ConfigCategoryAdd",
    hidden: true,
    props: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/h5/category/add"));
      });
    },
    meta: {
      title: "添加品类",
      icon: "",
      activeMenu: "/h5Config/category",
      parentTitle: "首页品类板块",
      parentRouteName: "h5ConfigCategory"
    }
  }, {
    path: "show",
    name: "h5ConfigShow",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/h5/show/index"));
      });
    },
    meta: {
      title: "首页展示板块",
      icon: ""
    }
  }, {
    path: "showAdd/:type/:editId?",
    name: "h5ConfigShowAdd",
    hidden: true,
    props: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/h5/show/add"));
      });
    },
    meta: {
      title: "添加板块",
      icon: "",
      activeMenu: "/h5Config/show",
      parentTitle: "首页展示板块",
      parentRouteName: "h5ConfigShow"
    }
  }]
}, {
  path: "/h5Recommend",
  name: "h5Recommend",
  component: _layout.default,
  redirect: "/h5Recommend/banner",
  meta: {
    title: "H5端推荐位管理",
    icon: "position"
  },
  children: [{
    path: "banner",
    name: "h5RecommendBanner",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/h5/banner/index"));
      });
    },
    meta: {
      title: "商城banner设置",
      icon: ""
    }
  }, {
    path: "activity",
    name: "h5RecommendActivity",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/h5/activity/index"));
      });
    },
    meta: {
      title: "首页活动板块",
      icon: ""
    }
  }, {
    path: "custom",
    name: "h5RecommendCustom",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/h5/custom/index"));
      });
    },
    meta: {
      title: "首页自定义板块",
      icon: ""
    }
  }]
}, {
  path: "/h5Spread",
  name: "h5Spread",
  component: _layout.default,
  redirect: "/h5Spread/spread",
  meta: {
    title: "H5端商品推广管理",
    icon: "position"
  },
  children: [{
    path: "spreadClassify",
    name: "h5SpreadClassify",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/h5/spread/spreadClassify/index"));
      });
    },
    meta: {
      title: "商品推广分类列表",
      icon: ""
    }
  }, {
    path: "spreadList",
    name: "h5SpreadList",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/h5/spread/spreadList/index"));
      });
    },
    meta: {
      title: "商品推广列表",
      icon: ""
    }
  }]
}, // 404 page must be placed at the end !!!
{
  path: "*",
  redirect: "/404",
  hidden: true
}];
exports.asyncRoutes = asyncRoutes;

var createRouter = function createRouter() {
  return new _vueRouter.default({
    mode: "history",
    // require service support
    scrollBehavior: function scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return {
          x: 0,
          y: 0
        };
      }
    },
    routes: constantRoutes
  });
};

var router = createRouter();

function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

var _default = router;
exports.default = _default;
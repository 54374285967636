"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/objectSpread2"));

var _lease = require("@/api/lease");

var _customPage = _interopRequireDefault(require("@/components/customPage"));

var _myDatePicker = _interopRequireDefault(require("@/components/myDatePicker"));

var _paramDialog = _interopRequireDefault(require("./child/paramDialog.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    customPage: _customPage.default,
    myDatePicker: _myDatePicker.default,
    paramDialog: _paramDialog.default
  },
  data: function data() {
    return {
      tableData: [],
      total: 0,
      searchForm: {
        orderNo: "",
        number: "",
        name: "",
        logisticsStatus: "",
        status: "",
        deliverTime: "",
        confirmTime: ""
      },
      dialogVisible: false,
      dialogType: 1,
      requestInfo: {},
      responseInfo: {}
    };
  },
  methods: {
    search: function search() {
      var pickerVal1 = this.$refs.myDatePicker1.getVal();
      var pickerVal2 = this.$refs.myDatePicker2.getVal();

      if (pickerVal1 !== 'error' || pickerVal2 !== 'error') {
        this.searchForm.deliverTime = pickerVal1;
        this.searchForm.confirmTime = pickerVal2;
        this.$refs.customPage.page = 1;
        this.getList();
      }

      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      if (this.$refs.myDatePicker1) {
        this.$refs.myDatePicker1.reset();
      }

      if (this.$refs.myDatePicker2) {
        this.$refs.myDatePicker2.reset();
      }

      this.searchForm = Object.assign({});
      this.getList();
    },
    jumpLogistics: function jumpLogistics(num) {
      var _this$$router$resolve = this.$router.resolve({
        path: "/logisticsH5",
        query: {
          orderNo: num
        }
      }),
          href = _this$$router$resolve.href;

      window.open(href, '_blank');
    },
    getParams: function getParams(row) {
      this.dialogType = 2;
      this.dialogVisible = true;
      var info = JSON.parse(row);
      this.requestInfo = info.request ? JSON.parse(info.request.biz_content) : {};
      this.responseInfo = info.response ? info.response : {};
    },
    closeDialog: function closeDialog() {
      this.dialogVisible = false;
    },
    getList: function getList() {
      var _this = this;

      var _this$$refs$customPag = this.$refs.customPage,
          page = _this$$refs$customPag.page,
          pageSize = _this$$refs$customPag.pageSize;
      (0, _lease.getLogisticsList)((0, _objectSpread2.default)({
        page: page,
        pageSize: pageSize
      }, this.searchForm)).then(function (res) {
        _this.tableData = res.data;
        _this.total = res.total;
      });
    }
  },
  mounted: function mounted() {
    this.getList();
  }
};
exports.default = _default;
"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getArticleList = getArticleList;
exports.addArticle = addArticle;
exports.searchArticle = searchArticle;
exports.searchSecondCategory = searchSecondCategory;
exports.delArticle = delArticle;
exports.editHome = editHome;
exports.editHot = editHot;
exports.editStatus = editStatus;

var _fetch = _interopRequireDefault(require("@/utils/fetch"));

// 平台文章列表
function getArticleList(params) {
  return _fetch.default.post("/Admin/PlatformArticle/platformArticleList", params);
} // 添加或编辑平台文章


function addArticle(params) {
  return _fetch.default.post("Admin/PlatformArticle/addOrEditPlatformArticle", params, true);
} // 根据id查询平台文章


function searchArticle(id) {
  return _fetch.default.get("Admin/PlatformArticle/platformArticleById", {
    id: id
  });
} // 获取二级分类


function searchSecondCategory(id) {
  return _fetch.default.get("Admin/PlatformArticle/secondCategory", {
    id: id
  });
} // 根据id删除平台文章


function delArticle(id) {
  return _fetch.default.post("/Admin/PlatformArticle/delPlatformArticle", {
    id: id
  }, true);
} // 根据id编辑首页推荐


function editHome(params) {
  return _fetch.default.post("/Admin/PlatformArticle/editHomeRecommend", params, true);
} // 根据id编辑热门推荐


function editHot(params) {
  return _fetch.default.post("/Admin/PlatformArticle/editHotRecommend", params, true);
} // 根据id编辑文章信息状态


function editStatus(params) {
  return _fetch.default.post("/Admin/PlatformArticle/editArticleStatus", params, true);
}
"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/objectSpread2"));

var _audit = require("@/api/audit");

var _customPage = _interopRequireDefault(require("@/components/customPage"));

var _audit2 = _interopRequireDefault(require("./components/audit"));

var _myDatePicker = _interopRequireDefault(require("@/components/myDatePicker"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    customPage: _customPage.default,
    auditDialog: _audit2.default,
    myDatePicker: _myDatePicker.default
  },
  data: function data() {
    return {
      searchForm: {
        goods_name: "",
        shop_name: "",
        create_time: ""
      },
      activeName: '1',
      count: {
        all_goods: 0,
        nocheck_count: 0,
        checked_count: 0,
        refuse_count: 0
      },
      total: 0,
      tableData: []
    };
  },
  methods: {
    // 通过
    pass: function pass(id) {
      var _this = this;

      this.$confirm("您确定要通过审核吗？", "通过审核", {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _audit.goodsShelfCheckPass)(id).then(function (res) {
          _this.getList(true);
        });
      }).catch(function () {});
    },
    // 拒绝
    refuse: function refuse(id) {
      this.$refs.auditDialog.goodsId = id;
      this.$refs.auditDialog.dialogType = 1;
      this.$refs.auditDialog.dialogVisible = true;
    },
    // 批量操作
    handleCommand: function handleCommand(command) {
      var list = this.$refs.multipleTable.selection;

      if (list.length === 0) {
        this.$message({
          message: "没有勾选项",
          type: "warning"
        });
        return;
      }

      this.$refs.auditDialog.goodsList = list;

      switch (command) {
        case "pass":
          this.$refs.auditDialog.dialogType = 2;
          this.$refs.auditDialog.dialogVisible = true;
          break;

        case "refuse":
          this.$refs.auditDialog.dialogType = 3;
          this.$refs.auditDialog.dialogVisible = true;
          break;

        default:
          break;
      }
    },
    // 获取数据
    getList: function getList(flag) {
      var _this2 = this;

      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
          page = _this$$refs$customPag.page,
          pageSize = _this$$refs$customPag.pageSize;

      if (!flag) {
        this.tableData = [];
      }

      (0, _audit.getGoodsShelfCheckList)((0, _objectSpread2.default)((0, _objectSpread2.default)({
        page: page,
        pageSize: pageSize
      }, this.searchForm), {}, {
        check_status: this.activeName
      })).then(function (res) {
        _this2.tableData = res.data || [];
        _this2.total = res.total;

        for (var key in _this2.count) {
          _this2.count[key] = res.count[key];
        }
      });
    },
    // 查询
    search: function search() {
      var pickerVal = this.$refs.myDatePicker.getVal();

      if (pickerVal !== 'error') {
        this.searchForm.create_time = pickerVal;
        this.$refs.customPage.page = 1;
        this.getList();
      }
    },
    // 重置
    reset: function reset() {
      if (this.$refs.myDatePicker) {
        this.$refs.myDatePicker.reset();
      }

      this.searchForm = {
        goods_name: "",
        shop_name: "",
        create_time: ""
      };
      this.getList();
    },
    handleClick: function handleClick(tab) {
      this.activeName = tab.name;
      this.$refs.customPage.page = 1;
      this.getList();
    }
  },
  mounted: function mounted() {
    this.getList();
  }
};
exports.default = _default;
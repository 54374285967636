"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _system = require("@/api/system");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ['memberId'],
  data: function data() {
    return {
      loading: false,
      form: {
        type: "alipay"
      },
      dialogVisible: false
    };
  },
  methods: {
    submit: function submit() {
      var _this = this;

      this.loading = true;
      (0, _system.upAliapp)({
        member_id: this.memberId,
        type: this.form.type
      }).then(function (res) {
        _this.loading = false;
        _this.dialogVisible = false;
      }).catch(function (err) {});
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {}
  }
};
exports.default = _default;
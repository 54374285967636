import { render, staticRenderFns } from "./message.vue?vue&type=template&id=476c5302&scoped=true&"
import script from "./message.vue?vue&type=script&lang=js&"
export * from "./message.vue?vue&type=script&lang=js&"
import style0 from "./message.vue?vue&type=style&index=0&id=476c5302&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "476c5302",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\www\\ssz_gold_backend\\multi_merchant_web\\gold_admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('476c5302')) {
      api.createRecord('476c5302', component.options)
    } else {
      api.reload('476c5302', component.options)
    }
    module.hot.accept("./message.vue?vue&type=template&id=476c5302&scoped=true&", function () {
      api.rerender('476c5302', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/thirdData/message.vue"
export default component.exports
"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find-index");

var _toConsumableArray2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/toConsumableArray"));

require("core-js/modules/es6.regexp.split");

var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/objectSpread2"));

require("core-js/modules/es6.string.sub");

require("core-js/modules/es6.function.name");

var _createForOfIteratorHelper2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/createForOfIteratorHelper"));

var _defineProperty2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/defineProperty"));

var _previewImg = _interopRequireDefault(require("@/components/previewImg"));

var _audit = require("@/api/audit");

var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));

var _validate = require("@/utils/validate");

var _order = require("@/api/order");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    previewImg: _previewImg.default,
    cropUpload: _cropUpload.default
  },
  props: ["viewId", "toEdit"],
  data: function data() {
    var validateTel = function validateTel(rule, value, callback) {
      if (!(0, _validate.checkMobile)(value)) {
        callback(new Error('联系人手机号格式错误'));
      } else {
        callback();
      }
    };

    var validateCompanyId = function validateCompanyId(rule, value, callback) {
      if (!(0, _validate.checkCompanyId)(value)) {
        callback(new Error('工商注册号格式错误'));
      } else {
        callback();
      }
    };

    var validateIdcard = function validateIdcard(rule, value, callback) {
      if (!(0, _validate.checkCardId)(value)) {
        callback(new Error('法人身份证号格式错误'));
      } else {
        callback();
      }
    };

    return {
      isEdit: false,
      tableData: [],
      viewForm: {},
      rules: (0, _defineProperty2.default)({
        shopname: [{
          required: true,
          message: '店铺名称不能为空',
          trigger: 'change'
        }],
        account: [{
          required: true,
          message: '请选择账户类型',
          trigger: 'change'
        }],
        account_type: [{
          required: true,
          message: '请选择是否开启风控',
          trigger: 'change'
        }],
        faptype: [{
          required: true,
          message: '请选择发票类型',
          trigger: 'change'
        }],
        shopcity: [{
          required: true,
          message: '当前经营位置不能为空',
          trigger: 'change'
        }],
        people: [{
          required: true,
          message: '店铺联系人不能为空',
          trigger: 'change'
        }],
        contact_tel: [{
          required: true,
          message: '联系人手机号不能为空',
          trigger: 'change'
        }, {
          validator: validateTel,
          trigger: 'change'
        }],
        mechanism: [{
          required: true,
          message: '公司名称不能为空',
          trigger: 'change'
        }],
        companyid: [{
          required: true,
          message: '工商注册号不能为空',
          trigger: 'change'
        }, {
          validator: validateCompanyId,
          trigger: 'change'
        }],
        company_area: [{
          required: true,
          message: '请选择公司所在区域',
          trigger: 'change'
        }],
        company: [{
          required: true,
          message: '公司地址不能为空',
          trigger: 'change'
        }],
        faname: [{
          required: true,
          message: '法人姓名不能为空',
          trigger: 'change'
        }],
        idcard: [{
          required: true,
          message: '法人身份证号不能为空',
          trigger: 'change'
        }, {
          validator: validateIdcard,
          trigger: 'change'
        }],
        shopcard: [{
          required: true,
          message: '请上传营业执照',
          trigger: 'change'
        }],
        photo: [{
          required: true,
          message: '请上传店铺头像',
          trigger: 'change'
        }],
        doorphoto: [{
          required: true,
          message: '请上传公司门头照',
          trigger: 'change'
        }],
        seal_url: [{
          required: true,
          message: '请上传公章图片',
          trigger: 'change'
        }],
        faidcard: [{
          required: true,
          message: '请上传身份证正面照',
          trigger: 'change'
        }],
        faidcard_back: [{
          required: true,
          message: '请上传身份证背面照',
          trigger: 'change'
        }]
      }, "company_area", [{
        required: true,
        message: '请选择公司所在区域',
        trigger: 'change'
      }]),
      optionProps: {
        value: 'name',
        label: 'name',
        expandTrigger: 'hover',
        children: 'sub'
      },
      regionList: [],
      areaList: [],
      shopcity: '',
      companyArea: ''
    };
  },
  methods: {
    getAreaId: function getAreaId(arr) {
      var _iterator = (0, _createForOfIteratorHelper2.default)(this.areaList),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var p = _step.value;

          if (p.name == arr[0]) {
            var _iterator2 = (0, _createForOfIteratorHelper2.default)(p.sub),
                _step2;

            try {
              for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                var c = _step2.value;

                if (c.name == arr[1]) {
                  return c.id;
                }
              }
            } catch (err) {
              _iterator2.e(err);
            } finally {
              _iterator2.f();
            }
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return '';
    },
    accountTypeChange: function accountTypeChange(val) {
      if (val == 1) {
        this.viewForm.account_type = 1;
      }
    },
    setUrl: function setUrl(key, value) {
      this.viewForm[key] = value;
    },
    // 取消更改 
    cancelEdit: function cancelEdit() {
      if (this.toEdit) {
        this.$router.back();
      } else {
        this.isEdit = false;
        this.getData();
      }
    },
    // 保存提交
    submit: function submit() {
      var _this = this;

      this.$refs['viewForm'].validate(function (valid) {
        if (valid) {
          var params = {
            photo: "",
            shopname: "",
            account: null,
            account_type: null,
            faptype: "",
            shopcity: "",
            people: "",
            contact_tel: "",
            mechanism: "",
            companyid: "",
            company_area: "",
            company: "",
            faname: "",
            idcard: "",
            shopcard: "",
            doorphoto: "",
            seal_url: "",
            faidcard: "",
            area_id: "",
            faidcard_back: ""
          };

          for (var key in params) {
            params[key] = _this.viewForm[key];
          }

          params.id = _this.viewId;
          params.area_id = _this.getAreaId(params.company_area);
          params.company_area = params.company_area.join('-');
          params.shopcity = params.shopcity.join('-');
          (0, _audit.editShopUserById)(params).then(function (res) {
            _this.$router.push({
              name: 'auditSupplierView',
              params: {
                viewId: _this.viewId
              }
            });

            _this.getData();
          });
        } else {
          _this.$message({
            message: "请检查表单错误",
            type: "error"
          });

          return false;
        }
      });
    },
    getData: function getData() {
      var _this2 = this;

      (0, _audit.shopUserById)(this.viewId).then(function (res) {
        _this2.viewForm = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, res.shopInfo), res.companyInfo);
        _this2.tableData = res.operationRecord;
        _this2.shopcity = _this2.viewForm.shopcity;

        var cityArr = _this2.viewForm.shopcity.split('-');

        _this2.viewForm.shopcity = cityArr;
        _this2.companyArea = _this2.viewForm.company_area;

        var areaArr = _this2.viewForm.company_area.split('-');

        _this2.viewForm.company_area = areaArr;
      });
    },
    getProvice: function getProvice() {
      var _this3 = this;

      (0, _order.getRegion)().then(function (res) {
        _this3.regionList = res ? res[0].sub : [];
        _this3.areaList = res ? (0, _toConsumableArray2.default)(res[0].sub) : [];

        var index = _this3.areaList.findIndex(function (k) {
          return k.id == 7459;
        });

        _this3.areaList.splice(index, 1);
      });
    }
  },
  mounted: function mounted() {
    this.isEdit = !!this.toEdit;
    this.getData();
    this.getProvice();
  }
};
exports.default = _default;
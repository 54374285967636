"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/objectSpread2"));

var _customPage = _interopRequireDefault(require("@/components/customPage"));

var _myDatePicker = _interopRequireDefault(require("@/components/myDatePicker"));

var _finance = require("@/api/finance");

var _view = _interopRequireDefault(require("./view"));

var _remark = _interopRequireDefault(require("./remark"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    customPage: _customPage.default,
    myDatePicker: _myDatePicker.default,
    viewDialog: _view.default,
    remarkDialog: _remark.default
  },
  data: function data() {
    return {
      searchForm: {
        orderNo: '',
        goodsName: '',
        shopName: '',
        receivingName: '',
        receivingMobile: '',
        sendStatus: '',
        min: '',
        max: '',
        leaseTime: ''
      },
      tableData: [],
      total: 0,
      overdueCount: 0,
      overdueAmount: 0,
      selectedItem: {}
    };
  },
  methods: {
    batchSend: function batchSend() {
      var _this = this;

      var list = this.$refs.multipleTable.selection;

      if (list.length === 0) {
        this.$message({
          message: "没有勾选项",
          type: "warning"
        });
        return;
      }

      var ids = list.map(function (k) {
        return k.lease_id;
      }).join(',');
      (0, _finance.sendOverdueSms)(ids).then(function (res) {
        _this.getList();
      });
    },
    // 发起代扣
    withholdItem: function withholdItem(orderId, leaseId) {
      var _this2 = this;

      this.$confirm("您确定要发起代扣吗？", "发起代扣", {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _finance.manualWithholding)({
          orderId: orderId,
          leaseId: leaseId
        }).then(function (res) {
          setTimeout(function () {
            _this2.getList();
          }, 1000);
        });
      }).catch(function () {});
    },
    handleMore: function handleMore(command, item) {
      this.selectedItem = item;

      if (command == 1) {
        // 查看记录
        this.$refs.viewDialog.dialogVisible = true;
      } else {
        // 查看备注
        this.$refs.remarkDialog.dialogVisible = true;
      }
    },
    goToOrder: function goToOrder(orderId) {
      if (orderId) {
        this.$router.push({
          name: 'orderList',
          params: {
            orderId: orderId
          }
        });
      }
    },
    exportFile: function exportFile() {
      var pickerVal = this.$refs.myDatePicker.getVal();

      if (pickerVal == 'error') {
        return;
      }

      this.searchForm.leaseTime = pickerVal;
      var params = (0, _objectSpread2.default)({}, this.searchForm);
      params.overdueDay = params.min && params.max ? params.min + '~' + params.max : '';
      delete params.min;
      delete params.max;
      (0, _finance.overdueListExport)(params);
    },
    changeNumberInput: function changeNumberInput(key, value) {
      var pattern = /^[1-9][0-9]*$/; // 正整数的正则表达式
      // 不符合正整数时

      if (!pattern.test(value)) {
        // input 框绑定的内容为空
        this.searchForm[key] = '';
      }
    },
    // 获取数据
    getList: function getList() {
      var _this3 = this;

      var _this$$refs$customPag = this.$refs.customPage,
          page = _this$$refs$customPag.page,
          pageSize = _this$$refs$customPag.pageSize;
      var params = (0, _objectSpread2.default)({}, this.searchForm);
      params.overdueDay = params.min && params.max ? params.min + '~' + params.max : '';
      delete params.min;
      delete params.max;
      (0, _finance.financeOverdueList)((0, _objectSpread2.default)({
        page: page,
        pageSize: pageSize
      }, params)).then(function (res) {
        var _res$list = res.list,
            data = _res$list.data,
            total = _res$list.total,
            _res$count = res.count,
            overdueCount = _res$count.overdueCount,
            overdueAmount = _res$count.overdueAmount;
        _this3.tableData = data || [];
        _this3.total = total || 0;
        _this3.overdueCount = overdueCount;
        _this3.overdueAmount = overdueAmount;
      });
    },
    // 查询
    search: function search() {
      var pickerVal = this.$refs.myDatePicker.getVal();

      if (pickerVal == 'error') {
        return;
      }

      this.searchForm.leaseTime = pickerVal;
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      if (this.$refs.myDatePicker) {
        this.$refs.myDatePicker.reset();
      }

      this.searchForm = {
        orderNo: '',
        goodsName: '',
        shopName: '',
        receivingName: '',
        receivingMobile: '',
        sendStatus: '',
        min: '',
        max: '',
        leaseTime: ''
      };
      this.getList();
    }
  },
  mounted: function mounted() {
    this.getList();
  }
};
exports.default = _default;
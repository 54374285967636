"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/objectSpread2"));

require("core-js/modules/es6.array.find");

require("core-js/modules/es6.function.name");

var _vuex = require("vuex");

//
//
//
//
//
//
var _default = {
  data: function data() {
    return {};
  },
  mounted: function mounted() {
    var routeName = this.permission_routes.find(function (item) {
      return !item.hidden && item.children;
    }).name;
    this.$router.push({
      name: routeName
    });
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['permission_routes']))
};
exports.default = _default;
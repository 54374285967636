"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.orderRefundList = orderRefundList;
exports.orderRefundInfo = orderRefundInfo;
exports.orderRefundTransData = orderRefundTransData;
exports.orderRefundDetail = orderRefundDetail;
exports.shopCheckPass = shopCheckPass;
exports.shopCheckRefuse = shopCheckRefuse;
exports.adminCheckPass = adminCheckPass;
exports.adminCheckRefuse = adminCheckRefuse;

var _fetch = _interopRequireDefault(require("@/utils/fetch"));

// 订单退款列表
function orderRefundList(params) {
  return _fetch.default.post("/Admin/OrderRefund/orderRefundList", params);
} // 订单退款详情


function orderRefundInfo(id) {
  return _fetch.default.get("/Admin/OrderRefund/orderRefundInfo", {
    order_id: id
  });
} // 退款订单补足金额明细


function orderRefundTransData(id) {
  return _fetch.default.get("/Admin/OrderRefund/orderRefundTransData", {
    refund_id: id
  });
} // 退款订单明细查看详情


function orderRefundDetail(id) {
  return _fetch.default.get("/Admin/OrderRefund/orderRefundDetail", {
    refund_id: id
  });
} // 商户审核通过


function shopCheckPass(id) {
  return _fetch.default.post("/Admin/OrderRefund/shopCheckPass", {
    refund_id: id
  }, true);
} // 商户审核拒绝


function shopCheckRefuse(params) {
  return _fetch.default.post("/Admin/OrderRefund/shopCheckRefuse", params, true);
} // 平台审核通过


function adminCheckPass(id) {
  return _fetch.default.post("/Admin/OrderRefund/adminCheckPass", {
    refund_id: id
  }, true);
} // 平台审核拒绝


function adminCheckRefuse(params) {
  return _fetch.default.post("/Admin/OrderRefund/adminCheckRefuse", params, true);
}
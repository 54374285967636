"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/objectSpread2"));

var _finance = require("@/api/finance");

var _customPage = _interopRequireDefault(require("@/components/customPage"));

var _myDatePicker = _interopRequireDefault(require("@/components/myDatePicker"));

var _huabeiDetails = _interopRequireDefault(require("./huabeiDetails"));

var _refuseSettle = _interopRequireDefault(require("./refuseSettle"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    customPage: _customPage.default,
    myDatePicker: _myDatePicker.default,
    huabeiDetails: _huabeiDetails.default,
    refuseSettle: _refuseSettle.default
  },
  data: function data() {
    return {
      searchForm: {
        order_no: "",
        user_name: "",
        shop_name: "",
        lease_term: "",
        pay_time: "",
        start_time: ""
      },
      activeName: "1",
      orderId: "",
      count: {
        no_settle: 0,
        settled: 0,
        refuse_settle: 0
      },
      leaseList: [{
        value: 1,
        label: "第1期"
      }, {
        value: 2,
        label: "第2期"
      }, {
        value: 3,
        label: "第3期"
      }, {
        value: 4,
        label: "第4期"
      }, {
        value: 5,
        label: "第5期"
      }, {
        value: 6,
        label: "第6期"
      }, {
        value: 7,
        label: "第7期"
      }, {
        value: 8,
        label: "第8期"
      }, {
        value: 9,
        label: "第9期"
      }, {
        value: 10,
        label: "第10期"
      }, {
        value: 11,
        label: "第11期"
      }, {
        value: 12,
        label: "第12期"
      }],
      total: 0,
      tableData: [],
      repealVisible: false
    };
  },
  methods: {
    // 结算
    settlement: function settlement(id) {
      this.orderId = id;
      this.$refs.huabeiDetails.dialogVisible = true;
    },
    // 拒绝
    refuse: function refuse(id) {
      this.orderId = id;
      this.$refs.refuseSettle.dialogVisible = true;
    },
    // 撤销
    refuseRefuse: function refuseRefuse(id) {
      this.orderId = id;
      this.repealVisible = true;
    },
    repealRefused: function repealRefused() {
      var _this = this;

      (0, _finance.huabeiCancelRefuseSettle)(this.orderId).then(function (res) {
        _this.repealVisible = false;

        _this.getList();
      });
    },
    jumpOrder: function jumpOrder(orderId) {
      this.$router.push({
        name: "orderList",
        params: {
          orderId: orderId
        }
      });
    },
    // 获取数据
    getList: function getList(flag) {
      var _this2 = this;

      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
          page = _this$$refs$customPag.page,
          pageSize = _this$$refs$customPag.pageSize;

      if (!flag) {
        this.tableData = [];
      }

      (0, _finance.huebeiSettleList)((0, _objectSpread2.default)((0, _objectSpread2.default)({
        page: page,
        pageSize: pageSize
      }, this.searchForm), {}, {
        settle_status: this.activeName
      })).then(function (res) {
        _this2.tableData = res.data || [];
        _this2.total = res.total;

        for (var key in _this2.count) {
          _this2.count[key] = res.count[key];
        }
      });
    },
    // 查询
    search: function search() {
      var pickerVal_pay = this.$refs.myDatePicker_pay.getVal();
      var pickerVal_start = this.$refs.myDatePicker_start.getVal();

      if (pickerVal_pay !== "error") {
        this.searchForm.pay_time = pickerVal_pay;
      }

      if (pickerVal_start !== "error") {
        this.searchForm.start_time = pickerVal_start;
      }

      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      if (this.$refs.myDatePicker_pay) {
        this.$refs.myDatePicker_pay.reset();
      }

      if (this.$refs.myDatePicker_start) {
        this.$refs.myDatePicker_start.reset();
      }

      this.searchForm = {
        order_no: "",
        user_name: "",
        shop_name: "",
        lease_term: ""
      };
      this.getList();
    },
    handleClick: function handleClick(tab) {
      this.activeName = tab.name;
      this.$refs.customPage.page = 1;
      this.getList();
    },
    exportFile: function exportFile() {
      (0, _finance.huebeiExport)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.searchForm), {}, {
        pay_time: this.searchForm.pay_time,
        start_time: this.searchForm.start_time,
        settle_status: this.activeName
      }));
    }
  },
  mounted: function mounted() {
    this.getList();
  }
};
exports.default = _default;
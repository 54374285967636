"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/objectSpread2"));

require("core-js/modules/es6.function.name");

var _user = require("@/api/user");

var _customPage = _interopRequireDefault(require("@/components/customPage"));

var _ipmanager = _interopRequireDefault(require("../system/merchant/ipmanager"));

var _uploadApp = _interopRequireDefault(require("./dialogs/uploadApp"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    customPage: _customPage.default,
    ipmanagerVue: _ipmanager.default,
    uploadApp: _uploadApp.default
  },
  props: ["shopname"],
  data: function data() {
    return {
      loading: true,
      searchForm: {
        shopname: "",
        account_num: "",
        status: null
      },
      editItem: {},
      total: 0,
      tableData: [],
      routerName: "",
      memberId: 0
    };
  },
  // computed: {
  //   shopname(){
  //     console.log('shopname1',this.$route.query.shopname)
  //   }
  // },
  created: function created() {
    // console.log('shopname1',this.$route.query.shopname)
    if (this.$route.query.shopname) {
      this.routerName = this.$route.query.shopname;
    }
  },
  mounted: function mounted() {
    this.getList();
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    if (from.name === "supplierView") {
      next(function (vm) {
        vm.getList();
      });
    } else {
      next();
    }
  },
  methods: {
    //管理IP
    handleIp: function handleIp() {
      var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      this.editItem.id = item.member_id;
      this.editItem.titleName = "供应商IP白名单设置";
      this.editItem.username = item.shopname; // console.log("this.editItem", this.editItem);

      this.$refs.ip.dialogVisible = true;
    },
    getList: function getList(flag) {
      var _this = this;

      this.loading = true; // flag为true, 则不刷新表格

      var _this$$refs$customPag = this.$refs.customPage,
          page = _this$$refs$customPag.page,
          pageSize = _this$$refs$customPag.pageSize;

      if (!flag) {
        this.tableData = [];
      }

      this.searchForm.shopname = this.searchForm.shopname || this.routerName;
      (0, _user.getSupplierList)((0, _objectSpread2.default)({
        page: page,
        pageSize: pageSize
      }, this.searchForm)).then(function (res) {
        _this.tableData = res.data || [];
        _this.total = res.total;
        _this.loading = false;
      });
    },
    handleMore: function handleMore(command, item) {
      console.log(command, item); // this.selectedItem = item;

      if (command == "edit") {
        // 编辑资料
        this.$router.push({
          name: "supplierView",
          params: {
            viewId: item.id,
            toEdit: true
          }
        });
      } else {// 查看备注
      }
    },
    // 查询
    search: function search() {
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      this.searchForm = {
        shopname: "",
        account_num: "",
        status: null
      };
      this.routerName = "";
      this.getList();
    },
    // 启用/禁用
    enable: function enable(id, endisable) {
      var _this2 = this;

      (0, _user.editSupplier)({
        id: id,
        endisable: endisable == 1 ? 2 : 1
      }).then(function (res) {
        _this2.getList(true);
      });
    },
    upAliapp: function upAliapp(item) {
      this.memberId = item.member_id;
      this.$refs.uploadApp.dialogVisible = true;
      return;
    },
    loginSupplier: function loginSupplier(item) {
      var _this3 = this;

      (0, _user.getSupplierOnceToken)(item.id).then(function (res) {
        var url = _this3.$globalObj.supplierUrl + "?token=".concat(res.token, "&type=0");
        console.log("item", url);

        _this3.$func.openWindow(url);
      });
    }
  }
};
exports.default = _default;
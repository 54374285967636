"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/objectSpread2"));

var _audit = require("@/api/audit");

var _login = require("@/api/login");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ['editId'],
  data: function data() {
    return {
      isLoading: false,
      form: {
        mobile: '',
        code: ''
      },
      rules: {
        code: [{
          required: true,
          message: '验证码不能为空',
          trigger: 'blur'
        }]
      },
      dialogVisible: false,
      showTime: false,
      // 显示60s倒计时
      timeCount: "",
      // 计时显示
      timer: null // 计时

    };
  },
  methods: {
    getCodes: function getCodes(mobile) {
      var _this = this;

      (0, _login.sendMobileCode)(mobile).then(function (res) {
        _this.showTime = true;
        var TIME_COUNT = 60;
        _this.timeCount = 60;
        _this.timer = setInterval(function () {
          if (_this.timeCount > 0 && _this.timeCount <= TIME_COUNT) {
            _this.timeCount--;
          } else {
            _this.showTime = false;
            clearInterval(_this.timer);
            _this.timer = null;
          }
        }, 1000);
      });
    },
    getPhoneCode: function getPhoneCode() {
      if (this.showTime === true) {
        return;
      }

      if (!this.form.mobile) {
        this.$message({
          message: "手机号未获取到",
          type: "warning"
        });
        return;
      }

      this.getCodes(this.form.mobile);
    },
    submit: function submit() {
      var _this2 = this;

      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          _this2.isLoading = true;
          (0, _audit.withdrawalPass)((0, _objectSpread2.default)({
            id: _this2.editId
          }, _this2.form)).then(function (res) {
            _this2.dialogVisible = false;
            _this2.isLoading = false;

            _this2.$emit('getList', true);
          }).catch(function (err) {
            _this2.dialogVisible = false;
            _this2.isLoading = false;

            _this2.$emit('getList', true);
          });
        } else {
          _this2.$message({
            message: "请检查表单错误",
            type: "error"
          });

          return false;
        }
      });
    },
    getMobile: function getMobile() {
      var _this3 = this;

      (0, _audit.getExamineMobile)().then(function (res) {
        _this3.form.mobile = res.mobile || '';
      });
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.getMobile();
      } else {
        this.$refs['ruleForm'].resetFields();
        this.form = {
          mobile: '',
          code: ''
        };
        this.showTime = false;
        this.timeCount = "";
        this.timer = null;
      }
    }
  }
};
exports.default = _default;
"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));

var _web = require("@/api/web");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    cropUpload: _cropUpload.default
  },
  props: ["type", "editId"],
  data: function data() {
    return {
      cateList: [],
      // 关联子分类列表
      form: {
        id: null,
        second_id: [],
        cate_name: "",
        cate_sort: 0,
        status: 1,
        cate_img: ""
      }
    };
  },
  computed: {
    rules: function rules() {
      if (this.type === "info") {
        return {
          cate_name: [{
            required: true,
            message: '品类一级名称',
            trigger: 'change'
          }],
          cate_img: [{
            required: true,
            message: '请上传缩略图',
            trigger: 'change'
          }]
        };
      } else {
        return {};
      }
    }
  },
  methods: {
    setUrl: function setUrl(val) {
      this.form.cate_img = val;
    },
    // 编辑获取信息
    getData: function getData() {
      var _this = this;

      (0, _web.h5HomeCategoryById)(this.editId).then(function (res) {
        _this.form.id = res.id;
        _this.form.cate_name = res.cate_name;
        _this.form.cate_img = res.cate_img;
        _this.form.cate_sort = res.cate_sort;
        _this.form.status = res.status;
      });
    },
    // 表单提交
    submitForm: function submitForm() {
      var _this2 = this;

      this.$refs['form'].validate(function (valid) {
        if (valid) {
          if (_this2.type === "info") {
            var _this2$form = _this2.form,
                cate_name = _this2$form.cate_name,
                cate_img = _this2$form.cate_img,
                cate_sort = _this2$form.cate_sort,
                status = _this2$form.status,
                id = _this2$form.id;
            (0, _web.addOrEditH5HomeCategory)({
              cate_name: cate_name,
              cate_img: cate_img,
              cate_sort: cate_sort,
              status: status,
              id: id
            }).then(function (res) {
              if (!_this2.editId) {
                // 新增跳转列表页
                _this2.$router.push({
                  name: "h5ConfigCategory"
                });
              }
            });
          } else {
            var _this2$form2 = _this2.form,
                second_id = _this2$form2.second_id,
                _status = _this2$form2.status,
                _id = _this2$form2.id;

            if (second_id.length === 0) {
              _this2.$message({
                message: "请选择关联子品类",
                type: "warning"
              });

              return;
            }

            (0, _web.addOrEditH5HomeSecondCategory)({
              parent_id: _id,
              cate_idstr: second_id.join(','),
              status: _status
            }).then(function (res) {
              _this2.$router.push({
                name: "h5ConfigCategory"
              });
            });
          }
        } else {
          _this2.$message({
            message: "请检查表单错误",
            type: "error"
          });

          return false;
        }
      });
    },
    // 重置
    resetForm: function resetForm() {
      this.$refs['form'].resetFields();

      if (this.type === "info" && this.editId) {
        this.getData();
      } else {
        this.form = {
          id: null,
          second_id: [],
          cate_name: "",
          cate_sort: 0,
          status: 1,
          cate_img: ""
        };
      }
    },
    // 获取下拉列表
    getList: function getList() {
      var _this3 = this;

      (0, _web.h5HomeSecondCategoryById)(this.editId).then(function (res) {
        _this3.cateList = res;
      });
    }
  },
  mounted: function mounted() {
    if (this.type === "info") {
      if (this.editId) {
        // 编辑信息
        this.$route.meta.title = "编辑品类模板";
        this.getData();
      } else {
        // 添加信息
        this.$route.meta.title = "添加品类模板";
      }
    } else {
      this.getList();
      this.form.id = this.editId;
      this.$route.meta.title = "关联子品类";
    }
  }
};
exports.default = _default;
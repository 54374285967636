"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _system = require("@/api/system");

var _add = _interopRequireDefault(require("./add"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    addDialog: _add.default
  },
  data: function data() {
    return {
      tableData: [],
      pItem: null,
      editItem: null
    };
  },
  methods: {
    // 删除
    removeItem: function removeItem(id) {
      var _this = this;

      this.$confirm('您确定要删除吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _system.delMenu)(id).then(function (res) {
          _this.getList();
        });
      }).catch(function () {});
    },
    // 更改状态
    changeStatus: function changeStatus(id, status) {
      var _this2 = this;

      var tip = "",
          title = "";

      if (status == 1) {
        tip = "您确定要禁用吗？";
        title = "禁用";
      } else {
        tip = "您确定要启用吗？";
        title = "启用";
      }

      this.$confirm(tip, title, {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _system.setMenu)({
          id: id,
          status: status == 1 ? 0 : 1
        }).then(function (res) {
          _this2.getList();
        });
      }).catch(function () {});
    },
    // 更改排序
    changeSort: function changeSort(item) {
      var _this3 = this;

      var params = {
        id: null,
        pid: null,
        title: "",
        controller: "",
        action: "",
        path: "",
        status: 1,
        type: 1,
        sort_num: 0
      };

      for (var key in params) {
        params[key] = item[key];
      }

      (0, _system.editMenu)(params).then(function (res) {
        _this3.getList();
      });
    },
    // 添加/编辑菜单
    openAddDialog: function openAddDialog(item, type) {
      this.pItem = null;
      this.editItem = null;

      if (type === "add") {
        this.pItem = item;
      } else if (item) {
        this.editItem = item;
      }

      this.$refs.addDialog.dialogVisible = true;
    },
    getList: function getList() {
      var _this4 = this;

      (0, _system.getMenuList)().then(function (res) {
        _this4.tableData = res || [];
      });
    }
  },
  mounted: function mounted() {
    this.getList();
  }
};
exports.default = _default;
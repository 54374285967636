"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/objectSpread2"));

var _web = require("@/api/web");

var _article = require("@/api/article");

var _textEditor = _interopRequireDefault(require("@/components/textEditor"));

var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ["type", "editId"],
  components: {
    textEditor: _textEditor.default,
    cropUpload: _cropUpload.default
  },
  data: function data() {
    return {
      list_Level1: [],
      list_Level2: [],
      articleForm: {
        article_title: "",
        keword: "",
        parentid: "",
        type_id: "",
        article_img: "",
        abstract: "",
        status: 1,
        content: "",
        recommend: ["home"],
        home_recommend: "1",
        hot_recommend: "2",
        author: "",
        platform: ["pc", "h5", "xcx"]
      },
      rules: {
        article_title: [{
          required: true,
          message: "请输入文章标题",
          trigger: "blur"
        }],
        parentid: [{
          required: true,
          message: "请选择一级分类",
          trigger: "change"
        }],
        type_id: [{
          required: true,
          message: "请选择二级分类",
          trigger: "change"
        }],
        article_img: [{
          required: true,
          message: "请上传文章缩略图",
          trigger: "change"
        }],
        abstract: [{
          required: true,
          message: "请输入文章摘要",
          trigger: "blur"
        }],
        content: [{
          required: true,
          message: "请输入文章内容",
          trigger: "change"
        }],
        recommend: [{
          // type: "array",
          required: true,
          message: "请选择推荐项",
          trigger: "change"
        }],
        status: [{
          required: true,
          message: "请选择发布状态",
          trigger: "change"
        }],
        author: [{
          required: true,
          message: "请输入作者",
          trigger: "blur"
        }],
        platform: [{
          required: true,
          message: "请选择展示平台",
          trigger: "change"
        }]
      }
    };
  },
  mounted: function mounted() {
    if (this.type === "edit") {
      this.$route.meta.title = "平台文章编辑";
      this.getEdit();
    }

    this.queryLevel1();
  },
  methods: {
    queryLevel1: function queryLevel1() {
      var _this = this;

      (0, _web.getInfoTypeList)().then(function (res) {
        _this.list_Level1 = res;
      });
    },
    searchSecond: function searchSecond(id) {
      var _this2 = this;

      (0, _article.searchSecondCategory)(id).then(function (res) {
        _this2.list_Level2 = res;
      });
    },
    changeLevel1: function changeLevel1(val) {
      this.list_Level2 = [];
      this.articleForm.type_id = "";
      this.searchSecond(val);
    },
    getEdit: function getEdit() {
      var _this3 = this;

      (0, _article.searchArticle)(this.editId).then(function (res) {
        if (res) {
          res.recommend = [];
          res.platform = [];

          _this3.searchSecond(res.parentid);

          _this3.articleForm = res;
          var temp_recom = "";

          if (res.home_recommend == 1 && res.hot_recommend == 1) {
            temp_recom = ["home", "hot"];
          } else if (res.home_recommend == 1) {
            temp_recom = ["home"];
          } else if (res.hot_recommend == 1) {
            temp_recom = ["hot"];
          } else {
            temp_recom = [];
          }

          _this3.articleForm.recommend = temp_recom;

          if (res.h5_show == 1) {
            _this3.articleForm.platform.push('h5');
          }

          if (res.pc_show == 1) {
            _this3.articleForm.platform.push('pc');
          }

          if (res.xcx_show == 1) {
            _this3.articleForm.platform.push('xcx');
          }
        }
      });
    },
    changeRadio: function changeRadio(val) {
      this.articleForm.status = val;
    },
    changeCheckBox: function changeCheckBox(val) {
      if (val.length == 2) {
        this.articleForm.home_recommend = "1";
        this.articleForm.hot_recommend = "1";
      } else if (val.length == 1) {
        this.articleForm.home_recommend = val[0] == "home" ? "1" : "2";
        this.articleForm.hot_recommend = val[0] == "hot" ? "1" : "2";
      }
    },
    submitForm: function submitForm(formName) {
      var _this4 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var recommend = _this4.articleForm;

          if (recommend.length == 2) {
            _this4.articleForm.home_recommend = "1";
            _this4.articleForm.hot_recommend = "1";
          } else if (recommend.length == 1) {
            _this4.articleForm.home_recommend = recommend[0] == "home" ? "1" : "2";
            _this4.articleForm.hot_recommend = recommend[0] == "hot" ? "1" : "2";
          }

          _this4.articleForm.h5_show = _this4.articleForm.platform.indexOf('h5') > -1 ? 1 : 2;
          _this4.articleForm.pc_show = _this4.articleForm.platform.indexOf('pc') > -1 ? 1 : 2;
          _this4.articleForm.xcx_show = _this4.articleForm.platform.indexOf('xcx') > -1 ? 1 : 2;
          var newForm = (0, _objectSpread2.default)({}, _this4.articleForm); // console.log("newForm", newForm);

          delete newForm.platform;
          delete newForm.recommend;
          (0, _article.addArticle)(newForm).then(function (res) {
            _this4.$router.push({
              name: "articlePlatform"
            });
          });
        }
      });
    },
    resetForm: function resetForm(formName) {
      if (this.type === "edit") {
        this.$route.meta.title = "平台文章编辑";
        this.getEdit();
      } else {
        this.$refs[formName].resetFields(); // this.articleForm.content = "";
      }
    },
    onEditorChange: function onEditorChange(val) {
      this.articleForm.content = val;
    }
  }
};
exports.default = _default;
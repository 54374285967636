"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _finance = require("@/api/finance");

var _customPage = _interopRequireDefault(require("@/components/customPage"));

var _add = _interopRequireDefault(require("./add"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    customPage: _customPage.default,
    addDialog: _add.default
  },
  data: function data() {
    return {
      total: 0,
      tableData: [],
      editItem: null
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList(flag) {
      var _this = this;

      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
          page = _this$$refs$customPag.page,
          pageSize = _this$$refs$customPag.pageSize;

      if (!flag) {
        this.tableData = [];
      }

      (0, _finance.getRateList)({
        page: page,
        pageSize: pageSize
      }).then(function (res) {
        console.log('res', res);
        _this.tableData = res || [];
        _this.total = res.length;
      });
    },
    calcCost: function calcCost(item) {
      var cost;

      if (item.is_percentage) {
        cost = item.rate_default * 100 + "%";
      } else {
        cost = "".concat(item.rate_default, "\u5143");
      }

      return cost;
    },
    // 添加/编辑账号
    openAddDialog: function openAddDialog() {
      var val = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      this.editItem = val;
      this.$refs.addDialog.dialogVisible = true;
    }
  }
};
exports.default = _default;
"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/objectSpread2"));

var _lease = require("@/api/lease");

var _customPage = _interopRequireDefault(require("@/components/customPage"));

var _paramDialog = _interopRequireDefault(require("./child/paramDialog.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    customPage: _customPage.default,
    paramDialog: _paramDialog.default
  },
  data: function data() {
    return {
      tableData: [],
      total: 0,
      searchForm: {
        orderNo: "",
        name: "",
        status: ""
      },
      dialogVisible: false,
      dialogType: 1,
      requestInfo: {},
      responseInfo: {}
    };
  },
  methods: {
    search: function search() {
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      this.searchForm = Object.assign({});
      this.getList();
    },
    getParams: function getParams(row) {
      this.dialogType = 2;
      this.dialogVisible = true;
      var info = JSON.parse(row);
      this.requestInfo = info.request ? JSON.parse(info.request.biz_content) : {};
      this.responseInfo = info.response ? info.response : {};
    },
    closeDialog: function closeDialog() {
      this.dialogVisible = false;
    },
    onChain: function onChain(row) {
      this.dialogType = 1;
      this.dialogVisible = true;
    },
    // 获取列表
    getList: function getList() {
      var _this = this;

      var _this$$refs$customPag = this.$refs.customPage,
          page = _this$$refs$customPag.page,
          pageSize = _this$$refs$customPag.pageSize;
      (0, _lease.getGoodsList)((0, _objectSpread2.default)({
        page: page,
        pageSize: pageSize
      }, this.searchForm)).then(function (res) {
        _this.tableData = res.data;
        _this.total = res.total;
      });
    }
  },
  mounted: function mounted() {
    this.getList();
  }
};
exports.default = _default;
"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _merchant = require("@/api/merchant");

var _customPage = _interopRequireDefault(require("@/components/customPage"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ["editItem", "supplierList"],
  components: {
    customPage: _customPage.default
  },
  data: function data() {
    return {
      dialogVisible: false,
      form: {
        supplierId: ''
      },
      bindIds: [],
      selectList: [],
      tableData: [],
      total: 0,
      orgAuthUrl: '',
      sealAuthUrl: '',
      rules: {
        supplierId: [{
          required: true,
          message: '请选择供应商',
          trigger: 'blur'
        }]
      }
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var _this = this;

      if (val) {
        this.$nextTick(function () {
          _this.init();
        });
      } else {
        var _this$$options$data = this.$options.data(),
            orgAuthUrl = _this$$options$data.orgAuthUrl,
            sealAuthUrl = _this$$options$data.sealAuthUrl,
            form = _this$$options$data.form;

        this.orgAuthUrl = orgAuthUrl;
        this.sealAuthUrl = sealAuthUrl;
        this.form = form;
        this.$refs['form'].resetFields();
        this.tableData = [];
      }
    }
  },
  methods: {
    init: function init() {
      this.getList();
    },
    getList: function getList() {
      var _this2 = this;

      var _this$$refs$customPag = this.$refs.customPage,
          page = _this$$refs$customPag.page,
          pageSize = _this$$refs$customPag.pageSize;
      (0, _merchant.getBindSupplierList)({
        page: page,
        pageSize: pageSize,
        merchant_id: this.editItem.id
      }).then(function (res) {
        _this2.tableData = res.data || [];
        _this2.total = res.total;
        var bindIds = [];

        if (_this2.tableData.length) {
          _this2.tableData.forEach(function (item) {
            bindIds.push(item.shop_id);
          });
        }

        _this2.selectList = _this2.supplierList.filter(function (item) {
          return bindIds.indexOf(item.id) == -1 && _this2.editItem.user_id != item.member_id;
        });
        _this2.bindIds = bindIds;
      });
    },
    saveBind: function saveBind() {
      var _this3 = this;

      this.$refs['form'].validate(function (valid) {
        if (valid) {
          (0, _merchant.saveBindSupplier)({
            merchant_id: _this3.editItem.id,
            supplier_id: _this3.form.supplierId
          }).then(function (res) {
            _this3.form.supplierId = '';

            _this3.getList();

            _this3.$emit("getList", true);
          });
        }
      });
    },
    delBind: function delBind(item) {
      var _this4 = this;

      this.$confirm('您确定要解绑吗?', '解绑', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _merchant.delBindSupplier)({
          merchant_id: _this4.editItem.id,
          supplier_id: item.shop_id
        }).then(function (res) {
          _this4.getList();

          _this4.$emit("getList", true);
        });
      }).catch(function () {});
    },
    // e签宝商户授权
    getEsignOrgId: function getEsignOrgId(item) {
      var _this5 = this;

      (0, _merchant.getEsignOrgId)({
        merchant_id: this.editItem.id,
        supplier_id: item.shop_id
      }).then(function (res) {
        console.log('res', res);

        if (res.data) {
          _this5.orgAuthUrl = res.data.authShortUrl;
        } else {
          _this5.getList();
        }
      }).catch(function (err) {});
      console.log('getEsignOrgId');
    },
    // e签宝印章授权
    getEsignSealId: function getEsignSealId(item) {
      var _this6 = this;

      (0, _merchant.getEsignSealId)({
        merchant_id: this.editItem.id,
        supplier_id: item.shop_id
      }).then(function (res) {
        console.log('res', res);

        if (res.data) {
          _this6.sealAuthUrl = res.data.authorizationSignShortUrl;
        } else {
          _this6.getList();
        }
      }).catch(function (err) {});
      console.log('getEsignSealId');
    }
  }
};
exports.default = _default;
"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

var _complaint = require("@/api/complaint");

var _dispose = _interopRequireDefault(require("./dailog/dispose"));

var _noteRecords = _interopRequireDefault(require("./dailog/noteRecords"));

var _previewImg = _interopRequireDefault(require("@/components/previewImg"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ["id"],
  components: {
    dispose: _dispose.default,
    noteRecords: _noteRecords.default,
    previewImg: _previewImg.default
  },
  data: function data() {
    return {
      suggestInfo: {},
      suggestList: [],
      imgList: [],
      suggestId: ""
    };
  },
  methods: {
    getList: function getList() {
      var _this = this;

      (0, _complaint.lookDetail)(this.id).then(function (res) {
        _this.suggestInfo = res.suggest;
        _this.suggestList = res.suggestLog;
        _this.imgList = res.suggest.images ? res.suggest.images.split(",") : [];
      });
    },
    toClose: function toClose() {
      this.$router.go(-1);
    },
    intervene: function intervene(id) {
      this.suggestId = id;
      this.$refs.disposes.dialogVisible = true;
    },
    remark: function remark(id) {
      this.suggestId = id;
      this.$refs.noteRecord.dialogVisible = true;
    }
  },
  mounted: function mounted() {
    this.getList();
  }
};
exports.default = _default;
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tab-title-page goods_list" },
    [
      _c(
        "div",
        { staticClass: "common_form_search" },
        [
          _c(
            "el-form",
            {
              ref: "searchForm",
              attrs: { inline: true, model: _vm.searchForm }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商品名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入商品名称", clearable: "" },
                    model: {
                      value: _vm.searchForm.goods_name,
                      callback: function($$v) {
                        _vm.$set(_vm.searchForm, "goods_name", $$v)
                      },
                      expression: "searchForm.goods_name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "供应商" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入供应商", clearable: "" },
                    model: {
                      value: _vm.searchForm.shop_name,
                      callback: function($$v) {
                        _vm.$set(_vm.searchForm, "shop_name", $$v)
                      },
                      expression: "searchForm.shop_name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "提交时间" } },
                [_c("my-date-picker", { ref: "myDatePicker" })],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.search()
                        }
                      }
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.reset()
                        }
                      }
                    },
                    [_vm._v("重置")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-tabs",
        {
          staticClass: "flex-1 nav_tabs",
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c("el-tab-pane", {
            attrs: { label: "全部(" + _vm.count.all_goods + ")", name: "1" }
          }),
          _c("el-tab-pane", {
            attrs: {
              label: "待审核商品(" + _vm.count.nocheck_count + ")",
              name: "3"
            }
          }),
          _c("el-tab-pane", {
            attrs: {
              label: "已审核商品(" + _vm.count.checked_count + ")",
              name: "4"
            }
          }),
          _c("el-tab-pane", {
            attrs: {
              label: "已拒绝商品(" + _vm.count.refuse_count + ")",
              name: "5"
            }
          })
        ],
        1
      ),
      _c("el-divider", { staticClass: "middle_divider" }),
      _c(
        "div",
        { staticClass: "child_page" },
        [
          _c(
            "div",
            { staticClass: "flex align-center table-top-btns" },
            [
              _c(
                "h5",
                { staticClass: "flex align-center" },
                [
                  _c("svg-icon", {
                    staticStyle: { "margin-right": "15px" },
                    attrs: { "icon-class": "list", size: 15 }
                  }),
                  _vm._v("数据列表\n      ")
                ],
                1
              ),
              (_vm.$hasMethod("#pass") || _vm.$hasMethod("#refuse")) &&
              _vm.activeName == 3
                ? _c(
                    "el-dropdown",
                    {
                      attrs: { trigger: "click" },
                      on: { command: _vm.handleCommand }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "add-btn svg-btn",
                          attrs: { size: "small", plain: "" }
                        },
                        [
                          _c("svg-icon", {
                            attrs: { "icon-class": "batch", size: 11 }
                          }),
                          _vm._v("批量操作\n        ")
                        ],
                        1
                      ),
                      _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        [
                          _vm.$hasMethod("#pass") &&
                          (_vm.activeName == 1 || _vm.activeName == 3)
                            ? _c(
                                "el-dropdown-item",
                                { attrs: { command: "pass" } },
                                [_vm._v("通过")]
                              )
                            : _vm._e(),
                          _vm.$hasMethod("#refuse") &&
                          (_vm.activeName == 1 || _vm.activeName == 3)
                            ? _c(
                                "el-dropdown-item",
                                { attrs: { command: "refuse" } },
                                [_vm._v("拒绝")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tableData.length > -1,
                  expression: "tableData.length > -1"
                }
              ],
              ref: "multipleTable",
              attrs: { data: _vm.tableData, border: "" }
            },
            [
              _vm.activeName == 3
                ? _c("el-table-column", {
                    attrs: { align: "center", type: "selection", width: "55" }
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: { align: "center", label: "商品信息", width: "300" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "flex align-center goods_info" },
                          [
                            _c(
                              "el-image",
                              {
                                staticStyle: { width: "60px", height: "60px" },
                                attrs: {
                                  src: scope.row.imgurl
                                    ? _vm.$globalObj.imgBaseUrl +
                                      scope.row.imgurl
                                    : ""
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "error" },
                                    slot: "error"
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-picture-outline"
                                    })
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "flex-1 text-left info" },
                              [
                                _c(
                                  "p",
                                  { staticClass: "activity" },
                                  [
                                    _vm._v(
                                      _vm._s(scope.row.id) +
                                        "\n                "
                                    ),
                                    _vm._l(scope.row.activity, function(
                                      item,
                                      index
                                    ) {
                                      return _c(
                                        "span",
                                        {
                                          key: index,
                                          staticClass: "inline-block v-middle"
                                        },
                                        [
                                          _c(
                                            "el-popover",
                                            {
                                              attrs: {
                                                placement: "top-start",
                                                width: "160",
                                                trigger: "hover",
                                                content: item.activity_title
                                              }
                                            },
                                            [
                                              item.activity_img
                                                ? _c("img", {
                                                    staticClass: "w-100 h-100",
                                                    attrs: {
                                                      slot: "reference",
                                                      src:
                                                        _vm.$globalObj
                                                          .imgApiUrl +
                                                        item.activity_img,
                                                      alt: item.activity_title
                                                    },
                                                    slot: "reference"
                                                  })
                                                : _vm._e()
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    })
                                  ],
                                  2
                                ),
                                _c("p", [_vm._v(_vm._s(scope.row.title))])
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { align: "center", prop: "cate_name", label: "品类" }
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "update_time",
                  label: "提交时间",
                  width: "100"
                }
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "供应商信息" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("p", [
                          !scope.row.shopname
                            ? _c(
                                "span",
                                { staticStyle: { margin: "0 5px 0 20px" } },
                                [_vm._v("默认")]
                              )
                            : _vm._e(),
                          _c(
                            "span",
                            {
                              staticClass: "blue-font",
                              on: {
                                click: function($event) {
                                  _vm.$func.openBlank(
                                    _vm.$router.resolve({
                                      path: "/supplier/view/" + scope.row.shopid
                                    })
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(_vm._s(scope.row.shopname)),
                              _c("p", [_vm._v(_vm._s(scope.row.shopmobile))])
                            ]
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-tag",
                          {
                            attrs: {
                              type:
                                scope.row.ischeck == 3
                                  ? ""
                                  : scope.row.ischeck == 4
                                  ? "success"
                                  : "danger"
                            }
                          },
                          [_vm._v(_vm._s(scope.row.ischeck_name))]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { align: "center", prop: "check_reason", label: "原因" }
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  align: "center",
                  label: "操作",
                  width: "240"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm.$hasMethod("#view")
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.$router.push({
                                      name: "auditViewGoods",
                                      params: { goodsId: scope.row.id }
                                    })
                                  }
                                }
                              },
                              [_vm._v("查看商品")]
                            )
                          : _vm._e(),
                        _vm.$hasMethod("#pass") && scope.row.ischeck == 3
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "warning", size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.pass(scope.row.id)
                                  }
                                }
                              },
                              [_vm._v("通过")]
                            )
                          : _vm._e(),
                        _vm.$hasMethod("#refuse") && scope.row.ischeck == 3
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "danger", size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.refuse(scope.row.id)
                                  }
                                }
                              },
                              [_vm._v("拒绝")]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c("custom-page", {
            ref: "customPage",
            attrs: { total: _vm.total },
            on: { getList: _vm.getList }
          })
        ],
        1
      ),
      _c("audit-dialog", { ref: "auditDialog", on: { getList: _vm.getList } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
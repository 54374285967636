"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/objectSpread2"));

var _order = require("@/api/order");

var _finance = require("@/api/finance");

var _deductLog = _interopRequireDefault(require("./deductLog"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ["orderId"],
  components: {
    deductLog: _deductLog.default
  },
  data: function data() {
    return {
      dialogVisible: false,
      tableData: [],
      selectedItem: {}
    };
  },
  methods: {
    switchChange: function switchChange(row) {
      var _this = this;

      (0, _finance.withholdSwitch)({
        orderId: row.order_id,
        leaseId: row.id,
        withholdStatus: row.withhold_status
      }).then(function (res) {
        _this.$parent.getList(true);
      });
    },
    viewLog: function viewLog(row) {
      this.selectedItem = (0, _objectSpread2.default)({}, row);
      this.$refs.deductLog.dialogVisible = true;
    },
    withholdItem: function withholdItem(row) {
      var _this2 = this;

      this.$confirm("您确定要发起代扣吗？", "发起代扣", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(function () {
        (0, _finance.manualWithholding)({
          orderId: row.order_id,
          leaseId: row.id
        }).then(function (res) {
          setTimeout(function () {
            _this2.$parent.getList(true);
          }, 1000);
        });
      }).catch(function () {});
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var _this3 = this;

      if (val) {
        (0, _order.getLease)(this.orderId).then(function (res) {
          _this3.tableData = res || [];
        });
      }
    }
  }
};
exports.default = _default;
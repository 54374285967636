"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _bannerForm = _interopRequireDefault(require("../components/bannerForm"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    bannerForm: _bannerForm.default
  },
  data: function data() {
    return {
      bannerList: []
    };
  },
  methods: {
    // 升序
    upSortItem: function upSortItem(index) {
      if (index === 0) {
        return;
      }

      var item = this.bannerList[index];
      this.$set(this.bannerList, index, this.bannerList[index - 1]);
      this.$set(this.bannerList, index - 1, item);
    },
    // 降序
    downSortItem: function downSortItem(index) {
      if (index === this.bannerList.length - 1) {
        return;
      }

      var item = this.bannerList[index];
      this.$set(this.bannerList, index, this.bannerList[index + 1]);
      this.$set(this.bannerList, index + 1, item);
    },
    // 删除推荐位
    deleteItem: function deleteItem(index) {
      this.bannerList.splice(index, 1);
    },
    addItem: function addItem() {
      this.bannerList.push({
        img: '',
        jumpType: '',
        jumpUrl: '',
        goods: {}
      });
    }
  }
};
exports.default = _default;
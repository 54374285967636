"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _createForOfIteratorHelper2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/createForOfIteratorHelper"));

require("core-js/modules/es6.regexp.split");

var _merchant = require("@/api/merchant");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ["list", "editItem"],
  data: function data() {
    return {
      dialogVisible: false,
      dialogTitle: '经营项目',
      form: {
        id: 0,
        category_ids: ""
      }
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var _this = this;

      if (val) {
        this.$nextTick(function () {
          _this.$refs['form'].resetFields();

          if (_this.editItem) {
            // 编辑
            _this.form.id = _this.editItem.id;
            _this.form.category_ids = _this.editItem.category_ids;

            var list = _this.editItem.category_ids.split(',');

            _this.$refs.tree.setCheckedKeys(list);
          }
        });
      } else {
        this.$refs.tree.setCheckedKeys([]);
        this.form = {
          category_ids: ""
        };
      }
    }
  },
  methods: {
    checkClick: function checkClick(data, checkObj) {
      var check = checkObj.checkedKeys.indexOf(data.id) > -1; // 如果有子节点，勾选子节点

      if (check && data.children) {
        this.checkChildren(data.children);
      }
    },
    checkChange: function checkChange(data, check) {
      // 父节点操作
      if (data.parentid != 0) {
        if (check === true) {
          // 如果选中，设置父节点为选中
          this.$refs.tree.setChecked(data.parentid, true);
        } else {
          // 如果取消选中，检查父节点是否该取消选中（可能仍有子节点为选中状态）
          var parentNode = this.$refs.tree.getNode(data.parentid);
          var parentHasCheckedChild = false;

          for (var i = 0, parentChildLen = parentNode.childNodes.length; i < parentChildLen; i++) {
            if (parentNode.childNodes[i].checked === true) {
              parentHasCheckedChild = true;
              break;
            }
          }

          if (!parentHasCheckedChild) this.$refs.tree.setChecked(data.parentid, false);
        }
      } // 子节点操作，如果取消选中，取消子节点选中


      if (data.children != null && check === false) {
        for (var j = 0, len = data.children.length; j < len; j++) {
          var childNode = this.$refs.tree.getNode(data.children[j].id);

          if (childNode.checked === true) {
            this.$refs.tree.setChecked(childNode.data.id, false);
          }
        }
      }
    },
    checkChildren: function checkChildren(childList) {
      var _iterator = (0, _createForOfIteratorHelper2.default)(childList),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;
          this.$refs.tree.setChecked(item.id, true);

          if (item.children) {
            this.checkChildren(item.children);
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    },
    submit: function submit() {
      var _this2 = this;

      var selectList = this.$refs.tree.getCheckedKeys();
      this.form.category_ids = selectList.join(',');
      (0, _merchant.editMerchantCategoryIds)(this.form).then(function (res) {
        _this2.dialogVisible = false;

        _this2.$emit("getList");

        _this2.$message.success('操作成功');
      });
    }
  }
};
exports.default = _default;
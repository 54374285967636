"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _user = require("@/api/user");

var _merchant = require("@/api/merchant");

var _customPage = _interopRequireDefault(require("@/components/customPage"));

var _bindSupplier = _interopRequireDefault(require("./bindSupplier"));

var _ipmanager = _interopRequireDefault(require("./ipmanager"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    customPage: _customPage.default,
    bindSupplier: _bindSupplier.default,
    ipmanagerVue: _ipmanager.default
  },
  data: function data() {
    return {
      searchForm: {
        mobile: "",
        status: null
      },
      isShowIp: false,
      total: 0,
      tableData: [],
      editItem: {},
      supplierList: []
    };
  },
  mounted: function mounted() {
    this.getList();
    this.getSupplierList();
  },
  methods: {
    getList: function getList(flag) {
      var _this = this;

      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
          page = _this$$refs$customPag.page,
          pageSize = _this$$refs$customPag.pageSize;

      if (!flag) {
        this.tableData = [];
      }

      (0, _merchant.getMerchantList)({
        page: page,
        pageSize: pageSize,
        status: this.searchForm.status === null ? -100 : this.searchForm.status,
        mobile: this.searchForm.mobile
      }).then(function (res) {
        _this.tableData = res.data || [];
        _this.total = res.total;
      });
    },
    getSupplierList: function getSupplierList() {
      var _this2 = this;

      (0, _user.getSupplierList)().then(function (res) {
        _this2.supplierList = res.data || [];
      });
    },
    // 删除
    removeItem: function removeItem(id) {
      var _this3 = this;

      this.$confirm("您确定要删除吗?", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(function () {
        delMerchant(id).then(function (res) {
          _this3.getList(true);
        });
      }).catch(function () {});
    },
    // 附加供应商
    bindSupplier: function bindSupplier() {
      var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      this.editItem = item;
      this.$refs.bindSupplier.dialogVisible = true;
    },
    //管理IP
    handleIp: function handleIp() {
      var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      this.editItem = item;
      this.editItem.titleName = "商户IP白名单设置";
      this.$refs.ip.dialogVisible = true;
    },
    // 添加/编辑账号
    openAddDialog: function openAddDialog(val) {
      this.editItem = val || null;
      this.$refs.addDialog.dialogVisible = true;
    },
    // 批量操作
    handleCommand: function handleCommand(command) {
      var list = this.$refs.multipleTable.selection;

      if (list.length === 0) {
        this.$message({
          message: "没有勾选项",
          type: "warning"
        });
        return;
      }

      var ids = list.map(function (item) {
        return item.id;
      }).join(",");

      switch (command) {
        case "delete":
          this.removeItem(ids);
          break;

        case "enable":
          this.changeStatus(ids, 0);
          break;

        case "disable":
          this.changeStatus(ids, 1);
          break;

        default:
          break;
      }
    },
    // 添加/查看 商户
    viewMerchant: function viewMerchant() {
      var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      this.$router.push({
        name: "merchantAccountView",
        params: {
          viewId: id
        }
      });
    },
    // 查询
    search: function search() {
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      this.searchForm = {
        mobile: "",
        status: null
      };
      this.getList();
    },
    // 启用/禁用
    changeStatus: function changeStatus(ids, status) {
      var _this4 = this;

      var tip = "",
          title = "";

      if (status == 1) {
        tip = "您确定要禁用吗？";
        title = "禁用";
      } else {
        tip = "您确定要启用吗？";
        title = "启用";
      }

      this.$confirm(tip, title, {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(function () {
        (0, _merchant.setMerchant)({
          ids: ids,
          status: status == 1 ? 0 : 1
        }).then(function (res) {
          _this4.getList(true);
        });
      }).catch(function () {});
    },
    loginMerchant: function loginMerchant(item) {
      var _this5 = this;

      (0, _merchant.getMerchantOnceToken)(item.id).then(function (res) {
        var url = "";

        if (res.domain_shop) {
          url = "http://" + res.domain_shop + "?token=".concat(res.token, "&type=1");
        } else {
          url = _this5.$globalObj.merchantUrl + "?token=".concat(res.token, "&type=1");
        } // console.log('url',url)


        _this5.$func.openWindow(url);
      });
    }
  }
};
exports.default = _default;
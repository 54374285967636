"use strict";

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/objectSpread2"));

var _myDatePicker = _interopRequireDefault(require("@/components/myDatePicker"));

var _customPage = _interopRequireDefault(require("@/components/customPage"));

var _data = require("@/api/data");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    myDatePicker: _myDatePicker.default,
    customPage: _customPage.default
  },
  data: function data() {
    return {
      searchForm: {
        mobile: '',
        template_id: '',
        send_status: '',
        create_time: '',
        shopname: ''
      },
      templateList: [],
      tableData: [],
      total: 0
    };
  },
  methods: {
    goToOrder: function goToOrder(orderId) {
      if (orderId) {
        this.$router.push({
          name: 'orderList',
          params: {
            orderId: orderId
          }
        });
      }
    },
    // 查询
    search: function search() {
      var pickerVal = this.$refs.myDatePicker.getVal();

      if (pickerVal !== 'error') {
        this.searchForm.create_time = pickerVal;
        this.$refs.customPage.page = 1;
        this.getList();
      }
    },
    // 重置
    reset: function reset() {
      if (this.$refs.myDatePicker) {
        this.$refs.myDatePicker.reset();
      }

      this.searchForm = {
        mobile: '',
        template_id: '',
        send_status: '',
        create_time: '',
        shopname: ''
      };
      this.getList();
    },
    changeNumberInput: function changeNumberInput(value) {
      var pattern = /^[1-9][0-9]*$/;

      if (!pattern.test(value)) {
        // input 框绑定的内容为空
        this.searchForm.mobile = '';
      }
    },
    getList: function getList() {
      var _this = this;

      var _this$$refs$customPag = this.$refs.customPage,
          page = _this$$refs$customPag.page,
          pageSize = _this$$refs$customPag.pageSize;
      (0, _data.msmUseList)((0, _objectSpread2.default)({
        page: page,
        pageSize: pageSize
      }, this.searchForm)).then(function (res) {
        var data = res.data,
            total = res.total;
        _this.tableData = data || [];
        _this.total = total || 0;
      });
    },
    getSelect: function getSelect() {
      var _this2 = this;

      (0, _data.getSmsTemplate)().then(function (res) {
        _this2.templateList = res || [];
      });
    }
  },
  mounted: function mounted() {
    this.getSelect();
    this.getList();
  }
};
exports.default = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

// import { getImgBaseUrl } from "./utils/auth"
// let imgBaseUrl = getImgBaseUrl()
var host = window.location.host;
var protocol = window.location.protocol;

function getUrl(hostname) {
  if (host.indexOf(hostname) != -1) {
    var hostArr = host.split('.');
    hostArr[0] = hostname == 'admin' ? 'supplier' : 'shop';
    host = hostArr.join('.');
  } // console.log('window.location',window.location)


  var url = "".concat(protocol, "//").concat(host, "/login"); // console.log('url',url)

  return url;
}

var globalObj = {
  title: '后台管理系统',
  // 系统名称
  imgApiUrl: process.env.VUE_APP_BASE_API + '/',
  // 图片api接口地址
  imgBaseUrl: 'https://images.shshzu.com/',
  // 阿里云图片地址 https://xxx.com/
  supplierUrl: getUrl('admin'),
  // 商户后台地址 http://xxx.com
  merchantUrl: getUrl('supplier') // 商户后台地址 http://xxx.com

};
var _default = globalObj;
exports.default = _default;
"use strict";

var _interopRequireWildcard = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/objectSpread2"));

var _createForOfIteratorHelper2 = _interopRequireDefault(require("D:/www/ssz_gold_backend/multi_merchant_web/gold_admin/node_modules/@babel/runtime/helpers/createForOfIteratorHelper"));

var _login2 = require("@/api/login");

var _auth = require("@/utils/auth");

var _router = _interopRequireWildcard(require("@/router"));

var state = {
  token: (0, _auth.getToken)(),
  menu: [],
  rule: [],
  ruleIds: []
};
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_MENU: function SET_MENU(state, menu) {
    state.menu = menu;
  },
  SET_RULE: function SET_RULE(state, rule) {
    state.rule = rule;
  },
  SET_RULEIDS: function SET_RULEIDS(state, ruleIds) {
    state.ruleIds = ruleIds;
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var commit = _ref.commit;
    var username = userInfo.username,
        password = userInfo.password;
    return new Promise(function (resolve, reject) {
      var name = username.trim();
      (0, _login2.login)({
        mobile: name,
        password: password
      }).then(function (response) {
        var data = response;
        commit("SET_TOKEN", data.token);
        (0, _auth.setToken)(data.token);
        (0, _auth.setName)(name);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // 获取用户授权菜单
  getMenu: function getMenu(_ref2) {
    var commit = _ref2.commit,
        state = _ref2.state;
    return new Promise(function (resolve, reject) {
      (0, _login2.getAuthRule)().then(function (response) {
        if (!response) {
          reject("授权失败，请重新登录！");
        }

        var menu = response.menu,
            rule = response.rule,
            ruleIds = response.ruleIds; // 如果没有页面级，则整个菜单隐藏

        var filterList = [];

        var _iterator = (0, _createForOfIteratorHelper2.default)(menu),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var item = _step.value;

            if (item.children) {
              var obj = (0, _objectSpread2.default)({}, item);
              obj.children = [];

              var _iterator2 = (0, _createForOfIteratorHelper2.default)(item.children),
                  _step2;

              try {
                for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                  var child = _step2.value;

                  if (child.children) {
                    obj.children.push(child);
                  }
                }
              } catch (err) {
                _iterator2.e(err);
              } finally {
                _iterator2.f();
              }

              if (obj.children.length > 0) {
                filterList.push(obj);
              }
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }

        commit("SET_MENU", filterList);
        commit("SET_RULE", rule);
        commit("SET_RULEIDS", ruleIds);
        resolve({
          menu: filterList
        });
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user logout
  logout: function logout(_ref3) {
    var commit = _ref3.commit,
        state = _ref3.state;
    return new Promise(function (resolve, reject) {
      (0, _login2.logout)().then(function () {
        commit("SET_TOKEN", "");
        commit("SET_MENU", []);
        commit("SET_RULE", []);
        commit("SET_RULEIDS", []);
        (0, _auth.removeToken)();
        (0, _auth.removeName)();
        (0, _router.resetRouter)();

        _router.default.push("/login");

        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // remove token
  resetToken: function resetToken(_ref4) {
    var commit = _ref4.commit;
    return new Promise(function (resolve) {
      commit("SET_TOKEN", "");
      commit("SET_MENU", []);
      commit("SET_RULE", []);
      commit("SET_RULEIDS", []);
      (0, _auth.removeToken)();
      (0, _auth.removeName)();
      resolve();
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;